@import '../../../../styles/shared.scss';

.concierge-summary-outer {
  .score-container {
    height: 150px;
    width: 300px;
    display: grid;
    place-content: center;
    border: $border-lightest;
    border-radius: 16px;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 1rem;

    .score {
      font-size: 2rem;
      font-weight: 600;

      .expected {
        font-size: 0.7rem;
        font-weight: 600;
      }
    }
  }

  .stats {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 10px;

    .stat-container {
      @extend %flex-col;
      gap: 10px;

      .stat {
        @extend %flex-row;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
        padding: 10px;
        font-size: 0.8rem;
        cursor: pointer;
        border: $border-lightest;
        border-radius: 8px;

        .stat-text {
          @extend %flex-row;
          justify-items: center;
          gap: 5px;
          font-weight: 600;

          .edit-icon {
            font-size: 0.6rem;
          }
        }

        .stat-value {
          font-weight: 600;
          font-style: italic;
          color: $dark;
          white-space: nowrap;
          font-size: 0.7rem;
        }
      }
    }
  }
}
