@import '../../styles/shared.scss';

.confirm-prompt-outer-container {
  font-family: $font-body;
  .header {
    margin: 0 0 8px;
    line-height: 1;
  }
  .subheader {
    font-size: 13px;
    margin: 0 0 8px;
  }

  textarea,
  input {
    @extend %input-shared;
    margin-top: 12px;
    width: 100%;
  }
  .cancel-btn {
    background: white;
    color: $dark;
    font-weight: bold;
  }
}
