@import '../../../styles/shared.scss';

.product-preview-outer-container {
  .product-preview {
    @extend %flex-row-middle;
    border-bottom: $border-offwhite;
    padding-bottom: 24px;
    margin-bottom: 24px;

    .empty {
      background: $offwhite;
    }

    img,
    .image {
      object-fit: contain;
      width: 200px;
      height: 200px;
      min-width: 200px;
      min-height: 200px;
      margin-right: 16px;
      border-radius: 8px;
    }

    .main-data {
      @extend %flex-col;
      .title {
        font-size: 20px;
        line-height: 24px;
        font-weight: bold;

        &.empty {
          width: 180px;
          height: 24px;
        }
      }
      .brand {
        font-size: 16px;
        line-height: 18px;

        &.empty {
          width: 120px;
          height: 14px;
          margin-top: 4px;
        }
      }
      .category {
        font-size: 12px;
        line-height: 14px;
        color: $light;
        margin-top: 2px;

        &.empty {
          width: 120px;
          height: 16px;
          background: transparent;
        }
      }

      .in-catalog-badge {
        font-size: 12px;
        background: $offwhite;
        border-radius: 2px;
        padding: 2px 4px;
        color: $dark;
        margin-top: 6px;
        font-weight: bold;
        align-self: flex-start;

        svg {
          margin-left: 4px;
        }
      }
    }

    .collection-product-img-wrapper {
      width: 200px;
      height: 200px;
      overflow: hidden;
      min-height: 200px;
      position: relative;
      margin: 0 auto 0;

      &.editing {
        img {
          filter: blur(4px);
          opacity: 0.4;
        }

        .loading-container {
          @extend %flex-center;
          position: absolute;
          z-index: 2;
          width: 100%;
          height: 100%;

          .loader {
            position: absolute;
            transform: translateY(-12px);
          }

          .message {
            transform: translateY(12px);
            font-size: 10px;
            font-weight: bold;
          }
        }
      }
      .transform-image-btns {
        @extend %flex-row;
        position: absolute;
        top: 0px;
        right: 0px;
        z-index: 2;

        .prettify-image-btn,
        .rotate-image-btn {
          font-size: 14px;
          background: rgba(255, 255, 255, 0.4);
          cursor: pointer;
          transition: transform 0.1s ease-in-out;
          color: $dark;
          padding: 6px;

          &:hover {
            color: $darkest;
            transform: scale(1.1);
          }
        }
      }

      .ReactCrop__rule-of-thirds-vt::before,
      .ReactCrop__rule-of-thirds-vt::after,
      .ReactCrop__rule-of-thirds-hz::before,
      .ReactCrop__rule-of-thirds-hz::after {
        background: $lightest;
      }

      .ReactCrop {
        width: 100%;
        height: 100%;

        & > div {
          width: 100%;
          height: 100%;
        }

        .ReactCrop__image {
          align-self: center;
          width: 100%;
          height: 100%;
          min-width: 200px;
          min-height: 200px;
          object-fit: contain;

          &.empty {
            background: $prettymuchwhite;
            border-radius: 4px;
          }
        }
      }
    }

    .product-image-controls {
      @extend %flex-center;
      padding: 12px 0;
      max-width: 200px;
      align-self: center;
      margin: 0 auto;

      .btn {
        font-size: 12px;
        padding: 6px 0;
        letter-spacing: 4px;
        flex: 1;

        &.apply-crop-btn {
          @extend %btn-dark;
        }

        &.remove-crop-btn {
          @extend %btn-text;
        }
      }
      .apply-crop-msg {
        font-size: 10px;
        text-align: center;
        background: white;
      }
    }
  }
}
