@import '../../styles/shared.scss';

.pending-payouts-outer-container {
  .payouts-header-container {
    @extend %flex-row-middle-spread;

    .stats {
      color: $medium;
      font-size: 12px;

      b {
        color: $darkest;
        font-size: 16px;
      }
    }
  }

  .icons {
    @extend %flex-center;
    .stripe-icon {
      color: $dark;
    }
  }
}
