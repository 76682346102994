@import '../../styles/shared.scss';

.brand-report-comments-outer {
  @extend %flex-col;
  align-items: flex-start;

  .brand-report-comments-inner {
    @extend %flex-col;
    gap: 2rem;
    box-sizing: border-box;
    padding: 0.8rem 1.6rem;
    max-width: 480px;
    width: 100%;

    &::-webkit-scrollbar {
      display: none;
    }

    .header {
      font-size: $font-size-l;
      font-weight: bold;
    }

    .sub-header {
      font-size: 0.7rem;
      color: dark;
    }

    .section {
      @extend %flex-col;

      .section-header-section {
        margin-bottom: 8px;
        color: $darkest;

        .section-header {
          text-transform: uppercase;
          font-size: 14px;
          font-weight: 700;
          margin-bottom: 0.7rem;
        }

        .section-subheader {
          font-size: 12px;
          font-weight: 400;
          margin-bottom: 0.7rem;
        }

        .section-manager {
          font-weight: bold;
        }
      }

      .sub-section {
        background-color: $prettymuchwhite;
        border-radius: 16px;
        position: relative;
        margin-bottom: 1rem;
        box-sizing: border-box;
        padding: 0.7rem 1.4rem;
        font-size: 0.9rem;
        position: relative;

        .sub-section-header {
          font-size: 0.6rem;
          text-transform: uppercase;
          margin-bottom: 2.5px;
        }
      }

      // .sub-section-body {

      // }
    }

    textarea {
      @extend %input-inline;
      // margin-top: 8px;
      font-weight: normal;
      background-color: transparent;
      font-family: $font-body;
      font-size: 14px;
      color: $darker;
      resize: none;
      background: white;
      padding: 12px;
      max-height: 240px;
    }
  }

  .padding {
    box-sizing: border-box;
    padding: 1rem 2rem;
  }
}
