@import '../../../../styles/shared.scss';

.score-breakdown-outer {
  margin-bottom: 2rem;

  .score-breakdown-inner {
    @extend %flex-col;
    gap: 1rem;

    .score-breakdown-section {
      width: 100%;
      position: relative;

      .score-breakdown-section-title {
        font-family: $font-body-new;
        font-weight: 600;
        font-size: 0.7rem;
        text-transform: uppercase;
        margin-bottom: 3px;
      }

      .background-pill {
        height: 20px;
        width: 100%;
        border-radius: 500px;
        background-color: $prettymuchwhite;
        border: $border-offwhite;
        position: relative;
        overflow: hidden;

        .foreground-pill {
          height: 100%;
          border-radius: 500px;
          background-color: $green;
          color: white;
          position: absolute;
          top: 0;
          left: 0;
        }

        .pill-label {
          background-color: $offwhite;
          font-family: $font-body-new;
          font-size: 0.75rem;
          font-weight: 600;
          position: absolute;
          height: 100%;
          display: grid;
          place-items: center;
          border-radius: 500px;
          border: $border-dark;
          padding: 0 10px;
          box-sizing: border-box;

          &.inactive {
            z-index: 0;
          }

          &.active {
            z-index: 1;
            transform: translateX(-50%);
          }
        }
      }
    }
  }
}
