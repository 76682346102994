%input-shared {
  padding: 8px 12px;
  border-radius: 4px;
  outline: none;
  border: $border-lightest;
  font-family: $font-body;
  font-size: 16px;

  &:focus {
    border: $border-light;
  }
}

%input-inline {
  @extend %input-shared;
  padding: 0;
  outline: none;
  font-family: $font-body;
  font-size: 14px;
  border: none;

  &:focus {
    border: none;
  }

  &::placeholder {
    color: $medium;
  }
}
