@import '../../../styles/shared.scss';

.concierge-sidebar-talent-export-outer {
  font-size: 0.8rem;

  .export-button {
    padding: 8px 8px;
    background-color: $white;
    cursor: pointer;
    border: $border-lighter;
    border-radius: 5px;
    text-align: center;
  }

  .talent-select-list {
    margin-top: 20px;
    @extend %flex-col;
    gap: 5px;

    .talent-select-item {
      @extend %flex-row;
      gap: 10px;
      padding: 5px;
      box-sizing: border-box;
      cursor: pointer;
      border-radius: 5px;
      border: $border-lightest;
      background-color: white;
      align-items: center;

      &.select-all {
        margin-bottom: 10px;
        border: none;
        background-color: transparent;
      }
    }
  }
}
