@import '../../../styles/shared.scss';

//* Scatter Plot Styles

.scatter-plot-outer {
  position: relative;
  .scatter-plot-legend {
    position: absolute;
    padding: 4px 8px;
    border-radius: 8px;
    border: 1px solid black;
    background-color: white;
    // fill: white;
    // stroke: 1px solid black;

    .scatter-plot-legend-header {
      // font: bold $font-social 16px;
      font-family: $font-social;
      font-size: 16px;
      font-weight: bold;
      fill: black;
    }

    .scatter-plot-legend-stats {
      font-size: 12px;
      font-weight: normal;
      fill: black;
    }
  }
}

//* Bar Chart Styles

.bar-graph-outer {
  @extend %flex-col;
  width: 100%;
  margin: 12px auto;
  // border-bottom: 2px solid $lightest;
  &.shortened {
    max-width: 500px;
  }

  .bar-graph-header {
    @extend %flex-center;
    position: relative;
    margin-bottom: 16px;

    .line {
      width: 100%;
      height: 1px;
      background: $darker;
    }

    p {
      font-family: $font-social;
      font-size: 16px;
      font-weight: bold;
      margin: 0 auto;
      padding: 4px 12px;
      z-index: 1;
      white-space: nowrap;
    }
  }

  .bar-graph-bars {
    min-height: 160px;
    padding: 0 24px;
    display: grid;
    gap: 1rem;
    margin-bottom: 20px;
    border-bottom: $border-dark;

    &.tinyColumns {
      gap: 2px;
    }

    .bar-graph-bar-holder {
      width: 100%;
      background-color: transparent;
      position: relative;

      &:last-child {
        .bar-graph-bar {
          background-color: $darkest;
          color: $white;
        }

        .bar-graph-bar .label {
          color: $darkest;
          font-weight: bold;
        }
      }

      .bar-graph-bar {
        position: absolute;
        width: 100%;
        background-color: $lighter;
        color: $darkest;
        border-radius: 8px 8px 0 0;
        bottom: 0;
        text-align: center;

        p {
          margin: 0;
          padding: 0;
          position: absolute;
          font-size: 12px;
          font-weight: bold;
          top: 8px;
          left: 50%;
          transform: translateX(-50%);
        }

        .label {
          position: absolute;
          color: $dark;
          font-size: 14px;
          font-weight: normal;
          top: calc(100% + 5px);
          left: 50%;
          transform: translateX(-50%);
          white-space: nowrap;
        }
      }
    }
  }
}
