@import '../../styles/shared.scss';

.edit-contract-template-pricing-modal-outer-container {
  @extend %flex-col;
  padding: 0 24px;
  position: relative;
  height: 100%;
  flex: 1;
  padding-bottom: 80px;

  h3 {
    margin: 12px 0 0;
  }
  .complex-toggle {
    @extend %btn-basic;
    font-size: 10px;
    margin-bottom: 12px;
  }

  &.complex {
    textarea {
      @extend %input-shared;
      border: 2px solid red !important;

      &.valid {
        border: 2px solid $success !important;
      }
    }
  }

  &.builder {
    textarea {
      @extend %input-shared;
      width: 100%;
      font-size: 12px;
    }
  }

  .create-base-table {
    @extend %btn-basic;
    text-transform: uppercase;
    font-size: 14px;
    text-align: center;
    padding: 12px 0;
  }

  .table {
    background: $offwhite;
    border-radius: 8px;
    overflow: hidden;
    margin-top: 12px;

    .row {
      @extend %flex-row-middle;
      padding: 4px 16px;
      border-bottom: $border-lightest;

      &:last-child {
        padding-bottom: 16px;
        border-bottom: none;
      }

      &.header-row {
        background: $lightest;
        font-weight: bold;
        color: $white;
      }
      .cell {
        flex: 1;
        font-size: 14px;

        input {
          @extend %input-inline;
          background: transparent;
          border: 1px solid rgba(0, 0, 0, 0.04);
          padding: 2px 4px;
        }
      }
      .delete {
        @extend %btn-basic;
      }
    }

    .add-row {
      @extend %btn-basic;
      width: 100%;
      padding: 12px 12px 12px;
      text-align: center;
      font-size: 12px;
      text-transform: uppercase;
    }
  }

  .actions {
    @extend %flex-row;
    justify-content: flex-end;
    margin-top: 12px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: $lighter;
    padding: 4px 12px;
    box-sizing: border-box;

    .action {
      &.cancel {
        @extend %btn-text;
      }
      &.save {
        @extend %btn-dark;
      }
    }
  }
}
