@import '../../../styles/shared.scss';

.product-match-view-container {
  max-width: 240px;
  padding-left: 32px;

  .resync-filters-button-container {
    @extend %flex-row;
    width: max-content;

    .resync-filters-button {
      @extend %flex-row;
      @extend %btn-basic;
      @extend %label;
      gap: 5px;
      font-size: 10px;
      padding: 2px;
      outline: none;
      border: none;
      color: $light;
      background: transparent;
      justify-content: flex-end;
      margin-bottom: 4px;
    }
  }

  .search-input {
    @extend %input-shared;
    margin-bottom: 4px;
    font-size: 16px;
    padding-left: 12px;
    z-index: 2;
  }

  .select {
    margin-bottom: 4px;
  }

  .match-items-list {
    @extend %flex-row;
    flex-wrap: wrap;

    .empty-msg {
      width: 100%;
      padding-top: 80px;
      text-align: center;
      color: $light;
    }
  }
}
