@import '../../../styles/shared.scss';

.product-catalog-hierarchy-view-outer-container.panel {
  max-width: 240px;
  padding-left: 32px;

  .search-input {
    @extend %input-inline;
    margin-bottom: 12px;
    font-size: 16px;
    padding-left: 12px;
    z-index: 2;
  }

  .row {
    @extend %flex-row-middle-spread;
    // background: $offwhite;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: bold;
    color: $dark;
    padding: 4px 12px;
    margin: 2px 0;
    cursor: pointer;

    &.level-two {
      padding-left: 24px;
    }
    &.level-three {
      padding-left: 48px;
    }

    &.active {
      font-weight: bold;
      color: $darkest;
      text-decoration: underline;
    }

    .main {
      &.lower {
        text-transform: none;
      }
    }
    .secondary {
      @extend %flex-row-middle;
      font-size: 12px;

      svg {
        margin-left: 6px;
      }
    }
  }
}
