@import '../../styles/shared.scss';

.comment-outer {
  .comment-inner {
    background-color: $white;
    border-radius: 4px;
    position: relative;
    margin-bottom: 1rem;
    box-sizing: border-box;
    padding: 12px 16px;
    font-size: 14px;
    position: relative;

    .note-textarea {
      @extend %input-inline;
      // margin-top: 8px;
      font-weight: normal;
      margin-bottom: 0px;
      background-color: transparent;
      font-family: $font-body;
      letter-spacing: -0.27px;
      line-height: 18px;
      color: $darker;
      max-width: 90%;
      width: 100%;
      resize: none;
    }

    .comment-header {
      @extend %flex-row-spread;
      font-size: 0.6rem;
      text-transform: uppercase;
      margin-bottom: 2.5px;
    }

    .comment-footer {
      display: flex;
      justify-content: flex-end;
      font-size: 0.6rem;
      text-transform: uppercase;
      margin-top: 2.5px;
      gap: 0.5rem;

      .comment-button {
        cursor: pointer;
        opacity: 0.4;
        transition: opacity 0.5s ease;

        &:hover {
          opacity: 1;
        }
      }
    }
  }
}
