@import '../../../styles/shared.scss';

.table-graph-outer {
  border-radius: 10px;
  overflow: hidden;
  background-color: white;
  margin: 1rem 0;
  border: $border-lighter;

  .table-graph-inner {
    box-sizing: border-box;
    text-align: center;

    .no-results {
      margin: 1rem 0;
      font-weight: 600;
    }

    .table-title {
      font-size: 1.1rem;
      font-weight: 600;
    }

    .rows {
      .row {
        @extend %flex-row;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 8px 12px;
        background-color: $prettymuchwhite;

        &:nth-child(odd) {
          background-color: white;
        }

        &:first-child {
          background-color: $darkest;
          .cell {
            color: white !important;
            font-size: 12px !important;
          }
        }

        .cell {
          @extend %flex-col-middle;
          width: 100px;
          overflow: visible;
          text-align: right;
          font-size: 12px;

          .cell-label {
            font-size: 0.8rem;
            color: $darkest;
            font-weight: bold;

            &.smallerLabel {
              font-size: 0.7rem;
            }
          }

          .cell-sub-label {
            font-size: 0.65rem;
            color: $dark;
            font-weight: normal;

            a {
              color: inherit;
            }
          }

          &.primary {
            text-align: left;
            font-size: 14px;
            font-weight: bold;
            color: $darkest;
            width: 250px;
          }

          &.smallerValues {
            font-size: 0.6rem;
          }
        }
      }
    }
  }
}
