%btn-shared {
  @extend %flex-center;
  border-radius: 4px;
  padding: 8px 12px;
  cursor: pointer;
  font-weight: bold;
  text-decoration: none;
  border: 1px solid transparent;
  &:hover {
    opacity: 0.85;
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
}

%btn-basic {
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}
%btn-inactive {
  cursor: default;
  &:hover {
    opacity: 1;
  }
}

%btn-outline {
  @extend %btn-shared;
  border-color: $dark;
  color: $darkest;
}

%btn-outline-light {
  @extend %btn-shared;
  border-color: $lighter;
  color: $medium;
}

%btn-offwhite {
  @extend %btn-shared;
  background-color: $offwhite;
  color: $dark;
}

%btn-light {
  @extend %btn-shared;
  background-color: $light;
  color: $white;
}

%btn-lighter {
  @extend %btn-shared;
  background-color: $lighter;
  color: $dark;
}

%btn-lightest {
  @extend %btn-shared;
  background-color: $lightest;
  color: $dark;
}

%btn-primary {
  @extend %btn-shared;
  background-color: $primary;
  color: $white;
}

%btn-secondary {
  @extend %btn-shared;
  background-color: $secondary;
  color: $white;
}

%btn-success {
  @extend %btn-shared;
  background-color: $success;
  color: $white;
}

%btn-dark {
  @extend %btn-shared;
  background-color: $dark;
  color: $white;
}
%btn-darker {
  @extend %btn-shared;
  background-color: $darker;
  color: $white;
}
%btn-darkest {
  @extend %btn-shared;
  background-color: $darkest;
  color: $white;
}

%btn-text {
  @extend %btn-shared;
  background-color: transparent;
  color: $dark;
}

%btn-inverted {
  @extend %btn-shared;
  background-color: $white;
  color: $primary;
}

%btn-secondary-inverted {
  @extend %btn-shared;
  border: 1px solid $secondary;
  color: $secondary;
}

%btn-radio {
  @extend %btn-shared;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  background: $offwhite;
  border: $border-lightest;
  color: transparent;
  text-align: center;
  padding: 0;

  &.selected {
    background: $success;
    border-color: transparent;
    color: white;
  }
}
