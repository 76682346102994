@import '../../styles/shared.scss';

.brands-outer-container {
  @extend %flex-col;
  font-family: $font-body;
  box-sizing: border-box;

  &.tagging {
    padding-right: 280px;
  }

  .brands-inner-container {
    @extend %flex-col;
    margin: 44px auto;
    width: 90%;
    align-items: stretch;
    position: relative;

    .section-header {
      @extend %flex-row-middle-spread;
      font-size: 20px;
      margin: 32px 0 16px;
      font-weight: bold;

      &.first {
        margin: -32px 0 16px;
      }

      .action-btns {
        @extend %flex-row-middle;

        .select {
          min-width: 300px;
          margin-right: 12px;
          font-size: 12px;
        }

        .brand-search {
          width: 400px;
          margin-right: 12px;
          padding: 10px 12px;
          font-size: 12px;
          border: $border-lighter;
          border-radius: 4px;
          font-family: $font-body;
          outline: none;
        }

        .action-btn {
          @extend %btn-dark;
          font-size: 12px;
        }
      }
    }

    .brands-table-container {
      display: flex;

      .brands-table {
        @extend %flex-col;
        width: min-content;
      }
    }

    .row {
      @extend %flex-row-middle;
      border-bottom: $border-offwhite;
      border-left: $border-offwhite;
      border-right: $border-offwhite;
      padding: 2px 0;
      width: 100%;
      font-size: 12px;
      color: $darker;

      &.header {
        background: $darker;
        color: $white;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;

        position: sticky;
        top: 0px;
      }

      &.odd {
        background: $prettymuchwhite;
      }

      .cell {
        padding: 2px 6px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        &.cell-header {
          position: sticky;
          left: 0px;
          font-weight: bold;

          background: transparent;
          color: black;
          backdrop-filter: blur(10px);
        }

        &.sm {
          width: 70px;
        }

        &.md {
          width: 150px;
        }

        &.lg {
          width: 200px;
        }

        &.xl {
          width: 250px;
        }

        &.complete {
          color: $dark;
        }

        &.incomplete {
          color: $error;
        }

        &.warning {
          @extend %btn-basic;
          background: $yield;
        }

        &.disabledCheck {
          @extend %btn-basic;
          opacity: 0.3;
        }

        img {
          height: 16px;
          max-width: 60px;
          object-fit: contain;
          margin: 4px 0;

          &.promotion-img {
            height: 24px;
          }
        }

        &.sortable {
          @extend %btn-basic;
        }

        &.btn {
          font-weight: bold;
          cursor: pointer;

          &.on {
            color: $success;
          }

          &:hover {
            opacity: 0.4;
          }

          &.error {
            color: $error;
          }
        }

        &.tags {
          @extend %flex-row;
          flex-wrap: wrap;

          .tag {
            @extend %btn-basic;
            font-size: 10px;
            border-radius: 2px;
            background: $dark;
            font-weight: bold;
            padding: 1px 3px;
            color: white;
            margin: 0 0 2px 2px;

            &.selected {
              background: $success;
              color: $white;
            }

            svg {
              margin-left: 2px;
              font-size: 8px;
            }
          }
        }

        .checkbox {
          @extend %btn-basic;
          width: 10px;
          min-width: 10px;
          height: 10px;
          min-height: 10px;
          border: $border-lighter;
          border-radius: 2px;
          margin-right: 4px;

          &.checked {
            background: $dark;
          }
        }
      }

      a {
        color: $dark;
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }
}
