@import '../../styles/shared.scss';

.contracts-outer-container {
  @extend %flex-center;
  font-family: $font-body;
  .contracts-inner-container {
    @extend %flex-col;
    margin: 16px auto 44px;
    max-width: 1800px;
    width: 90%;

    .controls {
      @extend %flex-row-middle-spread;
      padding-bottom: 8px;

      input {
        @extend %input-shared;
        min-width: 280px;
        font-size: 14px;
        padding: 4px 12px;
      }

      select {
        @extend %select-shared;
        margin: 0 4px;
        min-width: 120px;
        padding: 4px 4px;
      }

      .statuses {
        @extend %flex-row;
        border: $border-dark;
        border-radius: 8px;

        .status {
          @extend %btn-basic;
          font-size: 11px;
          padding: 2px 6px;
          font-weight: bold;
          text-transform: uppercase;
          white-space: nowrap;

          &:first-child {
            padding-left: 8px;
          }
          &:last-child {
            padding-right: 8px;
          }

          &.selected {
            background: $dark;
            color: white;
          }
        }
      }
    }

    .row {
      @extend %flex-row-middle;
      border-bottom: $border-offwhite;
      border-left: $border-offwhite;
      border-right: $border-offwhite;
      padding: 2px 0;
      font-size: 13px;
      &.odd {
        background: $prettymuchwhite;
      }
      &.error {
        background: rgba(255, 0, 0, 0.3);
      }
      &.warning {
        background: $yield;
      }

      &.header {
        background: $darker;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;

        .cell {
          color: white;
          overflow: visible;
        }
      }

      .cell {
        @extend %flex-row;
        flex: 1;
        padding: 2px 8px;
        white-space: nowrap;
        color: $darker;
        overflow: hidden;

        select {
          border: none;
          font-size: 12px;
          font-family: $font-body;
          outline: none;

          &.names {
            max-width: 16px;
          }
        }

        .tooltip-icn {
          color: white;
        }

        a {
          color: $dark;
          font-weight: bold;
          font-size: 10px;

          svg {
            font-size: 10px;
            margin-right: 4px;
          }
        }

        &.sales {
          @extend %flex-row;
          flex-wrap: wrap;

          .sale {
            @extend %btn-basic;
            @extend %flex-center;
            align-self: center;
            background: $dark;
            color: $darkest;
            padding: 1px 4px;
            border-radius: 3px;
            font-size: 10px;
            font-weight: bold;
            margin: 1px 2px;

            svg {
              font-size: 10px;
              margin-bottom: -1px;
            }

            // https://htmlcolorcodes.com/color-chart/ row 3
            // prettier-ignore
            &.harry {background: $harry}
            // prettier-ignore
            &.chris {background: $chris}
            // prettier-ignore
            &.tiffany {background: $tiffany}
            // prettier-ignore
            &.em {background: $em}
            // prettier-ignore
            &.austin {background: $austin}
            // prettier-ignore
            &.rachel {background: $rachel}
            // prettier-ignore
            &.kellee {background: $kellee}
            // prettier-ignore
            &.leslie {background: $leslie}
          }
        }

        svg {
          margin-left: 4px;
          font-size: 12px;
        }

        &.xs {
          max-width: 24px;
        }

        &.sm {
          max-width: 40px;
        }

        &.md {
          max-width: 72px;
          width: 72px;
          min-width: 72px;
        }

        &.lg {
          max-width: 200px;
          min-width: 180px;
        }
        &.xl {
          max-width: 320px;
          min-width: 280px;
        }

        &.clickable {
          @extend %btn-basic;
        }

        &.payment {
          font-size: 10px;
          text-transform: uppercase;
          font-weight: bold;
        }

        .strikethrough {
          text-decoration: line-through;
        }

        .quick-pay,
        .already-paid {
          @extend %btn-basic;
          font-size: 10px;
          text-transform: uppercase;
          font-weight: bold;

          svg {
            font-size: 9px;
            margin-right: 2px;
          }
        }
      }
    }

    .tasks {
      padding: 24px 12px;

      .task {
        @extend %flex-row-middle;
        font-size: 12px;
        margin-bottom: 2px;

        select {
          @extend %select-shared;
          min-width: 80px;
        }

        .badge {
          // text-transform: uppercase;
          font-weight: bold;
        }

        .cell {
          margin-left: 8px;

          &.error {
            @extend %flex-center;
            background: $error;
            font-weight: bold;
            color: white;
            padding: 1px 4px;
            border-radius: 4px;
          }

          &.warning {
            @extend %flex-center;
            background: $yield;
            font-weight: bold;
            padding: 1px 4px;
            border-radius: 4px;
          }
        }
      }
    }
  }
}
