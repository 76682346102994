@import '../../styles/shared.scss';

.analytics-outer-container {
  @extend %flex-center;
  font-family: $font-body;

  .analytics-inner-container {
    @extend %flex-col-center;
    margin: 44px auto;

    @include xl {
      flex-direction: row;
      align-items: flex-start;
    }

    .tabs-container {
      @extend %flex-row;
      align-self: center;
      border-radius: 8px;
      overflow: hidden;

      .tab {
        background: $prettymuchwhite;
        padding: 6px 32px;
        font-weight: bold;
        cursor: pointer;

        &.active {
          background: $dark;
          color: white;
        }
      }
    }

    .download-btn {
      font-size: 12px;
      cursor: pointer;
    }

    .analytics-side-container {
      @extend %flex-col;
      border-bottom: $border-lighter;
      margin: 0 0 12px;
      padding: 0 0 12px;
      max-height: 30vh;
      overflow: auto;
      width: 90vw;

      @include xl {
        border-right: none;
        border-bottom: $border-lighter;
        margin: 0 12px 0 0;
        padding: 0 12px 0 0;
        max-width: 310px;
        min-width: 310px;
        max-height: 100vh;
      }

      .section {
        margin-bottom: 24px;

        .sidebar-header {
          margin: 0 0 8px;
          font-weight: bold;
        }
      }

      .tabs-container {
        align-self: flex-start;
        margin-bottom: 12px;

        .tab {
          padding: 4px 10px;
          font-size: 13px;
          white-space: nowrap;
        }
      }

      .user-cell {
        font-size: 13px;
        cursor: pointer;
        margin-bottom: 4px;

        &.active {
          font-weight: bold;
        }
      }
    }
    .analytics-main-container {
      @extend %flex-col;
      min-width: 90vw;
      max-width: 90vw;
      max-height: 100vh;
      transition: opacity 0.2s ease-in-out;
      overflow: auto;

      @include xl {
        min-width: 880px;
        max-width: 880px;
      }

      @include xxl {
        min-width: 1440px;
        max-width: 1440px;
      }

      &.fetching {
        opacity: 0.3;
      }

      .header-container {
        @extend %flex-row-middle-spread;
        margin-bottom: 24px;

        .tabs-container {
          .tab {
            padding: 6px 16px;
            font-size: 14px;
          }
        }
      }

      .analytics-main-body {
        .table {
          @extend %flex-col;

          .row {
            @extend %flex-row;
            border-bottom: $border-lightest;

            &.row-header {
              background: $offwhite;
              border-top-left-radius: 2px;
              border-top-right-radius: 2px;
              margin-bottom: 4px;

              .cell {
                cursor: pointer;
                padding-left: 4px;
                &:hover {
                  opacity: 0.6;
                }
              }
            }

            .cell {
              flex: 1;
              min-width: 80px;
              max-width: 80px;
              padding: 6px 24px 6px 0;
              white-space: nowrap;
              font-size: 13px;

              &.tiny {
                min-width: 40px;
                max-width: 40px;
              }

              &.small {
                min-width: 64px;
                max-width: 64px;
              }

              &.medium {
                min-width: 120px;
                max-width: 120px;
              }

              &.btn {
                cursor: pointer;

                &:hover {
                  opacity: 0.8;
                }
              }

              &.wide {
                min-width: 180px;
                max-width: 180px;
              }

              &.dark {
                min-width: 240px;
                max-width: 240px;
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }
}
