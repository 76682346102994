@import '../../../styles/shared.scss';

.concierge-modal-outer {
  max-width: 1524px !important;
  top: auto !important;
  height: 70vh !important;
  width: 70vw !important;
  max-height: 800px !important;
  background-color: white;
  border-radius: 16px;
  position: relative;

  .concierge-modal-inner {
    @extend %flex-row;
    padding: 0 !important;
    overflow: hidden !important;
    border-radius: 10px;
    position: relative;
    height: 100%;

    .sidebar-container {
      position: relative;
      max-width: 300px;
      height: 100%;
      border-right: $offwhite 1px solid;
    }

    .main-container {
      @extend %flex-row;
      position: relative;
      flex: 1;
      overflow-x: scroll;
    }
  }
}
