@import '../../../styles/shared.scss';

.concierge-modal-sidebar-outer {
  box-sizing: border-box;
  padding: 25px;
  height: 100%;
  overflow-y: scroll;

  // background-color: red;

  .concierge-sidebar-container {
    margin: 1.5rem 0px;
    text-align: left;

    .image-container {
      display: flex;
      justify-content: center;
      // margin-bottom: 2rem;

      .image {
        width: 100%;
        aspect-ratio: 1;
        // height: 180px;
        // width: 180px;
        object-fit: cover;
        border-radius: 8px;
      }

      .image-replacement {
        display: grid;
        place-items: center;
        height: 180px;
        width: 180px;
        margin: 0px;
        padding: 0px;
        font-size: 4rem;
        font-weight: 600;
        font-family: $font-header;
        background-color: black;
        color: white;
        border-radius: 10px;
      }
    }

    a {
      display: inline-block;
      color: $dark;
    }

    .heading {
      font-size: 1.4rem;
      font-weight: 600;
      font-family: $font-header;
      // text-align: center;
    }

    .subheading {
      @extend %flex-col;
      gap: 1.5rem;

      div {
        @extend %flex-col;
        gap: 2px;
        font-size: 0.75rem;
        color: $dark;
        // text-align: center;

        .clickable {
          cursor: pointer;
        }

        >div {
          @extend %flex-row;
          // justify-content: center;
        }

        svg {
          height: 10px;
          margin-left: 5px;
        }

        .edit-button {
          color: $medium;
          text-decoration: underline;
          cursor: pointer;
        }

        .social-link-container {
          @extend %flex-row;
          gap: 5px;
          margin-top: 3px;

          .social-link img {
            height: 15px;
          }
        }
      }
    }

    .important-text {
      font-size: 0.9rem;
      font-weight: 600;
    }

    .select {
      font-size: 0.7rem;
      color: $dark;
    }

    .time-interval-container {
      @extend %flex-row;
      overflow: hidden;
      border-radius: 8px;
      border: $border-lightest;
      width: max-content;

      .time-interval {
        text-transform: uppercase;
        letter-spacing: 0.1rem;
        color: $dark;
        font-size: 12px;
        font-weight: 700;
        padding: 12px 8px;
        cursor: pointer;

        +.time-interval {
          border-left: $border-lightest;
        }

        &.selected {
          color: $lightest;
          background-color: $dark;
        }
      }
    }

    &.controls {
      margin-top: 3rem;
    }

    .controls-container {
      margin: 1rem 0;

      .controls-container-header {
        font-size: 0.6rem;
        text-transform: uppercase;
        font-weight: 600;
        color: $light;
        margin: 5px 0;
      }

      .date-picker-wrapper {
        width: 100%;
        display: block;
        overflow: visible;
      }

      .date-picker {
        display: block;
        width: 100%;
        padding: 8px;
        border-radius: 4px;
        border: $border-lighter;
        outline: none;
        box-sizing: border-box;
        overflow: visible;
        transition: border 0.5s ease;
        font-family: 'Karla';
        font-size: 0.7rem;
        color: $dark;

        &:focus {
          border: $border-dark;
        }
      }

      .notes-container {
        @extend %flex-col;
        gap: 10px;

        .note-outer {
          border: $border-lightest;
          border-radius: 16px;
          padding: 15px;
          height: max-content;
          position: relative;
          cursor: default;

          &:hover {
            .comment-icons {
              opacity: 1;
            }
          }

          .comment-icons {
            @extend %flex-row;
            gap: 5px;
            position: absolute;
            top: 0;
            right: 0;
            transform: translate(0, -7px);
            opacity: 0;
            transition: opacity 0.2s ease-out;

            .comment-icon {
              display: grid;
              place-content: center;
              height: 10px;
              width: 10px;
              border-radius: 50%;
              background-color: $offwhite;
              border: $border-lightest;
              font-size: 0.6rem;
              cursor: pointer;
              padding: 5px;

              &.isRead {
                background-color: $dark;
                color: $prettymuchwhite;
              }
            }
          }

          &.add-comment {
            display: grid;
            place-content: center;
            cursor: pointer;
          }

          .set-read-comment {
            display: grid;
            place-content: center;
            position: absolute;
            top: 0;
            left: 0;
            transform: translate(-30%, -30%);
            height: 20px;
            width: 20px;
            border-radius: 50%;
            background-color: $offwhite;
            border: $border-lightest;
            font-size: 0.6rem;
            cursor: pointer;
            opacity: 0;
            transition: opacity 0.2s ease-out;

            &.isRead {
              background-color: $dark;
              color: $prettymuchwhite;
            }
          }

          .note-inner {
            height: max-content;

            &.isRead {
              opacity: 0.3;
            }

            .comment {
              font-size: 0.9rem;
              font-family: $font-body;
              color: $dark;

              .comment-text {
                margin-bottom: 10px;
                cursor: pointer;
              }

              .authors {
                font-size: 0.6rem;
                color: $medium;
              }
            }

            .buttons {
              @extend %flex-row;
              align-items: center;
              gap: 10px;

              .button {
                background-color: $offwhite;
                border-radius: 50%;
                padding: 10px;
                display: grid;
                place-items: center;
                cursor: pointer;
              }
            }
          }
        }
      }
    }
  }
}
