@import '../../../styles/shared.scss';

.ml-api-controls-outer-container {
  margin-bottom: 24px;

  input {
    @extend %input-shared;
    width: 100%;
  }
}
