@import '../../styles/shared.scss';

.newsletter-tags-container-outer {
  padding: 12px 24px;
  margin-top: 12px;

  .tag-header {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 6px;
  }

  .newsletter-tags {
    @extend %flex-row-middle;
    flex-wrap: wrap;
    gap: 8px;

    .tag-container {
      padding-bottom: 8px;

      &.active {
        border-bottom: 4px solid $primary;
      }

      &.hidden {
        opacity: 0.5;
      }

      .tag {
        @extend %flex-row-middle;
        font-size: 10px;
        font-weight: 500;
        padding: 12px 16px;
        border-radius: 4px;
        background-color: white;
        border: $border-lightest;
        transition: opacity 0.2s ease-in-out;
        cursor: pointer;

        &:hover:not(.active) {
          opacity: 0.8;
        }

        .title-container {
          .title {
            @extend %label;
          }

          .num-visible {
            font-size: 10px;
            color: $light;
          }
        }

        &.active {
          background-color: $darkest;
          color: white;
          border: $border-darkest;
        }

        .edit-actions {
          @extend %flex-row-middle;
          margin-left: 12px;
          gap: 8px;

          .action {
            @extend %btn-basic;
            color: $light;
          }
        }
      }
    }

    .tag-actions {
      @extend %flex-row-middle;
      gap: 12px;

      .action {
        @extend %btn-basic;
        color: $light;
      }
    }
  }
}
