@import '../../../styles/shared.scss';

.match-ticket-container {
  border: $border-offwhite;
  padding: 12px;
  border-radius: 12px;
  margin-top: 12px;
  background: white;
  position: relative;
  cursor: pointer;
  width: 100%;

  &.selected {
    border: 1px solid $light;
    box-shadow: $shadow-dark;
  }

  .ticket-header {
    display: none;
  }

  .match-ticket {
    @extend %flex-row-middle;
    
    img {
      width: 120px;
      height: 120px;
      align-self: center;
      margin-right: 8px;
      object-fit: contain;
    }

    .product-data {
      @extend %flex-col;

      svg {
        font-size: 14px;
        margin-left: 6px;
        cursor: pointer;

        &:hover {
          opacity: 0.6;
        }
      }
      
      .title {
        font-size: 16px;
        font-weight: bold;
        cursor: text;
      }
      .brand {
        cursor: text;
        svg {
          font-size: 12px;
        }
      }

      .retailer {
        color: $dark;
      }
      .link {
        color: $dark;
        font-size: 12px;
      }
    }
  }

  .no-match-btn {
    @extend %btn-offwhite;
    position: absolute;
    bottom: 4px;
    right: 4px;
    font-size: 12px;
  }
}