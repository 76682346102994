@import '../../../styles/shared.scss';

.section-item {
  @extend %flex-row-middle-spread;
  position: relative;
  margin-bottom: 24px;
  padding: 12px 8px;
  background-color: $white;
  border-radius: 10px;

  &:not(:last-child) {
    border-bottom: 1px solid $offwhite;
  }

  .data {
    @extend %flex-row;
    .image {
      width: 80px;
      height: 80px;
      margin-right: 12px;
      border-radius: 4px;
      overflow: hidden;

      img {
        object-fit: cover;
        height: 100%;
        width: 100%;
      }

      .empty {
        background: $offwhite;
        height: 100%;
        width: 100%;
      }
    }
    .meta {
      @extend %flex-col;
      margin-right: 24px;

      .title {
        font-size: 16px;
        font-weight: bold;
        svg {
          margin: -1px 0 0 4px;
          font-size: 14px;
        }
      }

      .subtitle {
        font-size: 12px;
        max-width: 440px;

        overflow: hidden;
        text-overflow: ellipsis;
        position: relative;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;

        svg {
          margin: -1px 0 0 4px;
          font-size: 8px;
        }
      }

      .button {
        @extend %btn-light;
        padding: 2px 8px;
        font-size: 10px;
        align-self: flex-start;
        margin-top: 12px;

        &.needs-action {
          @extend %btn-darkest;
        }
      }

      .domain,
      .url {
        margin-top: 8px;
        font-size: 12px;
        font-weight: bold;
        text-transform: uppercase;
        svg {
          margin: -1px 0 0 4px;
          font-size: 10px;
        }
      }
      .badge {
        margin-top: 8px;
        font-size: 12px;
        color: $medium;

        svg {
          margin: -1px 0 0 4px;
          font-size: 10px;
        }

        &.active {
          align-self: flex-start;
          padding: 2px 6px;
          background: $darkest;
          color: white;
          border-radius: 4px;
          font-size: 12px;
          font-weight: bold;
        }
      }

      .dates {
        @extend %flex-row-middle;
        margin-top: 8px;
        text-transform: uppercase;

        .date {
          font-size: 14px;
          color: $light;
          text-decoration: underline;

          &.active {
            font-weight: bold;
            color: $darkest;
          }
        }
        .to {
          font-size: 10px;
          margin: 1px 6px 0;
          font-weight: bold;
          color: $light;
          font-weight: bold;
        }
      }

      .cta {
        margin-top: 8px;
        font-size: 10px;
        font-weight: bold;
        text-transform: uppercase;

        svg {
          margin-left: 4px;
          font-size: 8px;
        }
      }

      .tags {
        @extend %flex-row-middle;
        margin-top: 8px;

        .tag {
          @extend %flex-row-middle;
          border: $border-dark;
          margin-right: 8px;
          padding: 1px 6px;
          border-radius: 24px;
          font-size: 10px;
          text-transform: uppercase;

          svg {
            margin-left: 4px;
          }
        }
      }
    }

    .clickable {
      @extend %btn-basic;
    }
  }

  .config {
    min-width: 320px;
    margin-right: 48px;

    .config-section {
      &:not(:last-child) {
        margin-bottom: 12px;
      }

      .config-section-header {
        text-transform: uppercase;
        color: $darkest;
        font-weight: bold;
        font-size: 10px;
      }

      .select {
      }
    }

    .done-btn {
      @extend %btn-darkest;
    }
  }

  .item-actions {
    @extend %flex-row;
    position: absolute;
    top: 8px;
    right: 8px;
    color: $dark;

    .item-action {
      @extend %btn-basic;
      margin-left: 8px;

      &.inactive {
        opacity: 0.7;
      }
    }
  }
}
