@import '../../styles/shared.scss';

.users-outer-container {
  @extend %flex-col;
  font-family: $font-body;
  box-sizing: border-box;

  &.tagging {
    padding-right: 280px;
  }

  .users-inner-container {
    @extend %flex-col;
    margin: 16px auto 44px;
    max-width: 1800px;
    width: 90%;

    .header-stats {
      @extend %flex-row-middle-spread;
      padding-bottom: 8px;

      .header-stat {
        margin-left: 12px;
        font-weight: bold;
        color: $darkest;
        font-size: 12px;
      }

      input {
        @extend %input-shared;
        min-width: 400px;
      }
    }

    .row {
      @extend %flex-row-middle;
      border-bottom: $border-offwhite;
      border-left: $border-offwhite;
      border-right: $border-offwhite;
      padding: 2px 0;
      font-size: 13px;

      &.header {
        background: $darker;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;

        .cell {
          color: white;
          overflow: visible;
        }
      }

      &.odd {
        background: $prettymuchwhite;
      }

      .cell {
        @extend %flex-row;
        flex: 1;
        padding: 2px 8px;
        white-space: nowrap;
        color: $darker;
        overflow: hidden;

        &.description.visible-description {
          white-space: normal;
        }

        svg {
          margin-left: 4px;
          font-size: 12px;
        }

        &.small {
          max-width: 24px;
        }

        &.md {
          max-width: 72px;
          width: 72px;
          min-width: 72px;
        }

        &.lg {
          max-width: 200px;
          min-width: 180px;
        }

        &.has-filter {
          cursor: pointer;
        }

        &.btn {
          font-weight: bold;
          cursor: pointer;

          &.on {
            color: $success;
          }

          &:hover {
            opacity: 0.4;
          }

          &.error {
            color: $error;
          }
        }

        .checkbox {
          @extend %btn-basic;
          width: 10px;
          min-width: 10px;
          height: 10px;
          min-height: 10px;
          border: $border-lighter;
          border-radius: 2px;
          margin-right: 4px;

          &.checked {
            background: $dark;
          }
        }

        .social-icon {
          width: 14px;
          height: 14px;
          margin-right: 4px;
          opacity: 0.9;

          &:hover {
            opacity: 0.6;
          }
        }

        &.proj {
          font-weight: bold;
          &.highest {
            color: $highest;
          }
          &.higher {
            color: $higher;
          }
          &.high {
            color: $high;
          }
          &.middle {
            color: $middle;
          }
          &.low {
            color: $low;
          }

          &.lower {
            color: $lower;
          }

          &.lowest {
            color: $lowest;
          }
        }

        &.ml-proj {
          font-weight: bold;
          .grade-10 {
            background-color: $highest;
          }
          .grade-9 {
            background-color: $highest;
          }
          .grade-8 {
            background-color: $higher;
          }
          .grade-7 {
            background-color: $higher;
          }
          .grade-6 {
            background-color: $high;
          }
          .grade-5 {
            background-color: $high;
          }
          .grade-4 {
            background-color: $lightest;
          }
          .grade-3 {
            background-color: $low;
          }
          .grade-2 {
            background-color: $lower;
          }
          .grade-1 {
            background-color: $lowest;
          }
        }

        &.tags {
          @extend %flex-row;
          flex-wrap: wrap;
          .tag {
            @extend %btn-basic;
            font-size: 10px;
            border-radius: 2px;
            background: $dark;
            font-weight: bold;
            padding: 1px 3px;
            color: white;
            margin: 0 0 2px 2px;

            &.general {
              // background: $lightest;
              // color: $darker;
            }
            &.catalog {
              // background: $lighter;
            }
            &.geo {
              // background: darkgreen;
            }
            &.social {
              // background: $light;
            }
            &.activity {
              // background: #8c5e0e; // brownish
            }
            &.behavior {
              // background: purple;
            }
            &.internal {
              // background: $lighter;
            }

            &.selected {
              background: $success;
              color: $white;
            }

            svg {
              margin-left: 2px;
              font-size: 8px;
            }
          }
        }

        .badge {
          align-self: center;
          font-size: 10px;
          border-radius: 2px;
          background: $dark;
          font-weight: bold;
          padding: 1px 3px;
          color: white;

          &.admin {
            background: $offwhite;
            color: $dark;
          }

          &.brand {
            background: $lightest;
            color: $dark;
          }

          &.enthusiast {
            background: $lightest;
            color: $dark;
          }

          &.ambassador {
            background: $medium;
          }
          &.trendsetter {
            background: $darker;
          }
          &.icon {
            background: $darkest;
          }

          &.unknown {
            background: red;
            color: white;
          }

          &.archived {
            color: $darkest;
            background: transparent;
            text-decoration: line-through;
          }
        }
      }
    }
  }
}
