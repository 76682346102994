@import '../../styles/shared.scss';

.user-waitlist-decision-modal-outer {
  background: white;
  max-height: 600px;
  overflow-y: auto;

  .user-waitlist-decision-modal-inner {
    @extend %flex-col;
    gap: 1.7rem;
    padding: 10px;

    .suggested-codes {
      @extend %flex-row;
      gap: 10px;
      margin: 20px 0;
      flex-wrap: wrap;

      .suggested-code {
        @extend %label;
        @extend %flex-row;
        @extend %btn-basic;
        gap: 4px;
        padding: 4px 8px;
        border: $border-lightest;
        border-radius: 4px;
        font-size: 10px;
      }
    }

    .header-container {
      .user-waitlist-decision-modal-title {
        font-size: 2rem;
        font-weight: 600;
        font-family: $font-header;
      }

      .user-waitlist-decision-modal-subtitle {
        font-size: 0.9rem;

        svg {
          @extend %btn-basic;
        }
      }

      .user-waitlist-decision-modal-socials {
        @extend %flex-row;
        gap: 5px;
        margin-top: 5px;

        .social-icon {
          height: 20px;
          width: 20px;
          object-fit: contain;
        }
      }

    }

    .pitch-container {
      font-size: 16px;
    }

    .form-container {
      @extend %flex-col;
      gap: 0.5rem;

      .form-group {
        label {
          @extend %label;
          font-size: 0.8rem;
          display: block;
          margin-bottom: 3px;
          box-sizing: border-box;
        }

        select {
          font-size: 0.8rem;
          padding: 0.5rem;
          border-radius: 4px;
          border: $border-light;
          width: 100%;
          cursor: pointer;
          box-sizing: border-box;
        }

        input {
          font-size: 0.8rem;
          padding: 0.5rem;
          border-radius: 4px;
          border: $border-light;
          width: 100%;
          box-sizing: border-box;
        }
      }
    }

    .email-preview-container {
      textarea {
        font-family: $font-body-new;
        font-size: 0.8rem;
        padding: 0.5rem;
        border-radius: 4px;
        border: $border-light;
        width: 100%;
        box-sizing: border-box;
        height: 200px;
      }
    }
  }
}
