@import '../../styles/shared.scss';

.unset-user-outer {
  @extend %flex-row;
  gap: 10px;
  align-items: center;
  padding: 10px;
  border: $border-lightest;
  border-radius: 10px;
  width: 400px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  .unset-user-image {
    width: 100px;
    height: 100px;
    border-radius: 8px;
    object-fit: cover;

    .unset-user-image-replacement {
      width: 100px;
      height: 100px;
      border-radius: 8px;
      background-color: $dark;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.5rem;
      font-weight: 600;
      font-family: $font-header;
      color: $prettymuchwhite;
    }
  }

  .unset-user-info {
    .shop-link {
      font-size: 1rem;
      font-weight: 600;
      font-family: $font-header;
      text-decoration: none;
      color: black;

      .href-icon {
        color: $dark;
        font-size: 0.6rem;
        margin-left: 5px;
      }
    }
  }

  .tier {
    font-size: 0.7rem;
    font-weight: 600;
    color: $dark;
  }

  p {
    margin: 0;
    padding: 0;
    font-size: 0.6rem;
    color: $medium;

    &.link {
      cursor: pointer;
      transition: color 0.2s ease-out;

      &:hover {
        color: $dark;
      }
    }

    &.joined {
      font-size: 0.5;
      font-style: italic;
      margin-top: 5px;
    }
  }
}
