@import '../../styles/shared.scss';

.brand-report-controls {
  @extend %flex-col;
  align-items: flex-end;
  ::-webkit-scrollbar {
    display: none;
  }

  .brand-report-controls-inner {
    @extend %flex-col;
    margin-top: 1rem;
    gap: 2rem;
    max-width: 400px;
    width: 100%;
    color: $black;

    .control-section {
      box-sizing: border-box;
      margin: 0 1rem;
      border-radius: 10px;

      .control-section-header {
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 0.7rem;
      }

      .control-section-child {
        font-size: 0.7rem;
        position: relative;

        .date-picker-wrapper {
          width: 100%;
          display: block;
          overflow: visible;
        }

        .date-picker {
          display: block;
          width: 100%;
          padding: 8px;
          border-radius: 4px;
          border: $border-lighter;
          outline: none;
          box-sizing: border-box;
          overflow: visible;
          transition: border 0.5s ease;
          font-family: 'Karla';
          font-size: 0.7rem;
          color: $dark;

          &:focus {
            border: $border-dark;
          }
        }

        .title {
          margin-bottom: 0.3rem;
        }

        + .control-section-child {
          margin-top: 1rem;
        }

        .clickable-page-section {
          cursor: pointer;
          margin: 0.5rem 0;
          opacity: 0.6;
          transition: opacity 0.5s ease;
          color: black;
          font-size: 13px;

          &:hover {
            opacity: 1;
          }
        }
      }

      .input {
        padding: 4px 8px;
        font-size: 0.7rem;
        color: $dark;
        border: $border-lighter;
        border-radius: 5px;
      }
    }
  }
}
