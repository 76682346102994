@import '../../../styles/shared.scss';

.newsletter-section-container {
  position: relative;
  border-bottom: 1px solid $offwhite;
  padding: 16px 20px 32px;
  background-color: $prettymuchwhite;

  &.black {
    background-color: $black;
  }

  &.darkest {
    background-color: $darkest;
  }

  &.darker {
    background-color: $darker;
  }

  &.dark {
    background-color: $dark;
  }

  &.light {
    background-color: $light;
  }

  &.lighter {
    background-color: $lighter;
  }

  &.lightest {
    background-color: $lightest;
  }

  &.offwhite {
    background-color: $offwhite;
  }

  &.prettymuchwhite {
    background-color: $prettymuchwhite;
  }

  &.white {
    background-color: $white;
  }

  // Inverting

  &.dark,
  &.darker,
  &.darkest {
    .title-section {
      .title {
        color: $white;

        &.empty {
          color: $lighter;
        }
      }

      .subtitle {
        @extend %btn-basic;
        font-size: 14px;
        color: $offwhite;

        &.empty {
          color: $lighter;
        }
      }
    }

    .section-actions {
      color: $white;

      .section-action {
        @extend %btn-basic;
        margin-left: 8px;

        &.inactive {
          color: $lightest;
        }
      }
    }
  }

  .title-section {
    margin-bottom: 12px;

    .type {
      margin-bottom: 4px;
      font-size: 10px;
      text-transform: uppercase;
    }

    .title {
      @extend %btn-basic;
      font-size: 22px;
      color: $darkest;
      font-weight: bold;
      font-family: $font-header;

      &.empty {
        color: $lighter;
      }
    }

    .subtitle {
      @extend %btn-basic;
      font-size: 14px;
      color: $darkest;

      &.empty {
        color: $lighter;
      }
    }

    .section-num-visible {
      font-size: 10px;
      margin-top: 12px;
    }
  }

  .section-header {
    font-size: 14px;
    font-weight: bold;
    color: $dark;
    margin-bottom: 4px;
  }

  .section-tabs,
  .section-tags {
    @extend %flex-row;
    flex-wrap: wrap;

    .section-tab,
    .section-tag {
      @extend %flex-col-center;
      margin-right: 8px;
      margin-bottom: 8px;
      font-size: 14px;
      color: $dark;
      cursor: pointer;
      padding: 4px 12px;
      border-radius: 4px;
      border: $border-offwhite;

      &.active {
        opacity: 1;
        border: $border-dark;
        background-color: $white;

        .title {
          font-weight: bold;
        }
      }

      .num-visible {
        @extend %btn-basic;
        font-size: 10px;
        text-decoration: none;
        font-weight: normal;
      }
    }
  }

  .section-items {
    @extend %flex-col;
  }

  .section-expand-items {
    @extend %btn-lightest;
    font-size: 16px;

    &.expanded {
      @extend %btn-darkest;
    }

    svg {
      margin-left: 6px;
      font-size: 13px;
    }
  }

  .section-actions {
    @extend %flex-row;
    position: absolute;
    top: 8px;
    right: 8px;
    color: $dark;

    .section-action {
      @extend %btn-basic;
      margin-left: 8px;

      &.inactive {
        opacity: 0.7;
      }
    }
  }
}
