@import '../../styles/shared.scss';

.tag-sidebar-outer-container {
  background: $offwhite;
  border-left: $border-lightest;
  box-shadow: $shadow-dark;
  font-family: $font-body;
  height: 100vh;
  max-height: 100vh;
  width: 320px;
  position: fixed;
  overflow: auto;
  box-sizing: border-box;
  top: 0;
  right: 0;
  padding: 12px 24px 160px 12px;
  transition: transform 0.1s ease-in-out;
  transform: translateX(100%);

  &.visible {
    transform: translateX(0%);
  }

  .sidebar-header {
    @extend %flex-row-middle-spread;
    padding-top: 24px;
    .sidebar-header-title {
      font-size: 18px;
      font-weight: bold;
      input {
        @extend %input-inline;
        background: transparent;
        font-size: 18px;
      }
    }
    .sidebar-header-toggle {
      @extend %btn-basic;
      font-size: 14px;
      font-weight: bold;
    }
  }

  .sidebar-active-tags,
  .sidebar-active-user-tags {
    @extend %flex-row;
    align-items: flex-start;
    position: relative;
    flex-wrap: wrap;
    margin-top: 12px;
    border: $border-lightest;
    padding: 12px;
    min-height: 20px;

    .active-tag {
      @extend %flex-row-middle;
      font-size: 11px;
      margin: 0 4px 4px 0;
      border-radius: 12px;
      border: $border-dark;
      padding: 1px 4px;
      cursor: pointer;

      svg {
        margin-left: 3px;
      }
    }
    .clear-all {
      @extend %btn-basic;
      position: absolute;
      top: 4px;
      right: 4px;
      font-size: 9px;
      text-transform: uppercase;
    }
    .empty {
      text-align: center;
      width: 100%;
      font-size: 12px;
      text-transform: uppercase;
      color: $light;
    }
  }

  .sidebar-body {
    padding-top: 24px;

    .hide-all-sections {
      @extend %btn-basic;
      font-size: 10px;
      text-align: right;
    }
    .section {
      padding: 4px 0;
      .section-header {
        @extend %flex-row-middle-spread;
        font-size: 14px;
        text-transform: uppercase;
        font-weight: bold;

        .title {
          @extend %btn-basic;
          @extend %flex-row-middle;

          .section-toggle {
            margin-left: 4px;
            color: $dark;
            font-size: 12px;
          }
        }

        .add-new {
          @extend %btn-basic;
          font-size: 12px;
        }
        .hidden-warning {
          @extend %btn-basic;
          font-size: 10px;
        }

        &.hidden {
          opacity: 0.3;
        }
      }
      .tags {
        padding: 4px 4px;
        .tag {
          @extend %flex-row-middle-spread;
          .value {
            @extend %flex-row-middle;
            font-size: 13px;
            cursor: pointer;
            gap: 4px;

            &:not(:last-child) {
              padding-bottom: 4px;
            }

            .checkbox {
              width: 10px;
              height: 10px;
              border: $border-lighter;
              border-radius: 2px;

              &.checked {
                background: $dark;
              }
            }

            .info {
              font-size: 10px;
            }

            .count {
              font-size: 10px;
              color: $medium;
            }

            .gpt-edit-wrapper {
              @extend %flex-row-middle;
              visibility: hidden;
              gap: 4px;

              > span {
                width: 24px;
              }

              &:hover {
                font-weight: bold;
              }
            }
          }
          .actions {
            @extend %flex-row-middle;
            .action {
              @extend %btn-basic;
              font-size: 12px;
              color: $lighter;
              margin-left: 4px;

              &.active {
                color: $darkest;
                font-weight: bold;
              }

              &.disabled {
                pointer-events: none;
              }

              &.apply {
                @extend %flex-center;
                background: $darker;
                color: $white;
                border-radius: 2px;
                font-size: 8px;
                padding: 1px 4px;
                text-transform: uppercase;
                text-align: center;
              }
            }
          }
          &:hover .gpt-edit-wrapper {
            visibility: visible;
            color: $lightGreen;
          }
        }
      }
    }
  }
}
.toggle-tag-sidebar-btn {
  background: white;
  padding: 4px 8px;
  border-radius: 10px;
  font-size: 10px;
  cursor: pointer;
  box-shadow: $shadow-dark;
  position: fixed;
  bottom: 12px;
  right: 12px;

  &:hover {
    opacity: 0.6;
  }
}
