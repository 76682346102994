@import '../../../styles/shared.scss';

.ml-api-results-outer-container {
  transition: opacity 0.2s ease-in-out;
  &.fetching {
    opacity: 0.2;
  }
  .ml-api-results {
    .ml-api-result {
      border: $border-lightest;
      margin-bottom: 12px;
      padding: 8px 12px;
      border-radius: 8px;
      a {
        color: $darkest;
      }
    }
  }
}
