@import '../../styles/shared.scss';

.brand-new-registration-code-outer-container {
  @extend %flex-col;
  padding: 24px;

  .input-group {
    margin-bottom: 12px;
    .title {
      font-size: 15px;
      font-weight: bold;
      color: $dark;
    }
    .subtitle {
      font-size: 12px;
      margin-bottom: 8px;
    }

    input {
      @extend %input-shared;
      width: calc(100% - 24px);
    }
  }
  .submit-btn {
    @extend %btn-dark;
    margin-top: 24px;
  }
}
