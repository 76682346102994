.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.image-block{
  box-sizing: border-box;
  margin: 10px;
  height: 250px;
  width: 250px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.image-block-container{
  display: flex;
  flex-wrap: wrap;
}

.image-block-cancel{
  height: 25px;
  width: 25px;
  background-color: red;
  color: white;
  text-align: center;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
