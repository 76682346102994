@import '../../styles/shared.scss';

.concierge-controls-outer {
  .select {
    font-size: 0.7rem;
    color: $dark;
    min-width: 270px;
  }

  .header {
    font-size: 2rem;
    font-weight: 600;
    font-family: $font-header;
    margin-bottom: 10px;
  }

  .controls {
    @extend %flex-row;
    gap: 1rem;

    .control-section {
      .control-section-header {
        text-transform: uppercase;
        font-size: 0.6rem;
        color: $light;
        margin-bottom: 5px;
      }
    }
  }
}
