@import '../../styles/shared.scss';

.payments-outer-container {
  padding-bottom: 240px;
  padding: 12px 32px;

  .accordion {
    .section {
      > .header {
        @extend %flex-row-middle-spread;
        width: 100%;
        padding: 6px 12px;
        font-size: 13px;
        font-weight: bold;
        cursor: pointer;
        border-bottom: $border-offwhite;
        background: $offwhite;
        margin-bottom: 8px;
        border-radius: 8px;
        color: $darker;

        .small {
          font-size: 14px;
          font-weight: normal;
        }

        svg {
          margin-left: 8px;
        }

        &.faded {
          color: $lighter;
          background: transparent;
          border-color: transparent;
        }
      }

      .body {
        border-radius: 8px;
        overflow: hidden;
        border: $border-lightest;
        margin: 12px 0 24px;

        .row {
          @extend %flex-row-spread;
          font-size: 14px;
          border-bottom: $border-offwhite;

          &.disabled {
            opacity: 0.3;
          }

          .cell {
            flex: 1;
            text-align: center;
            padding: 8px 0;

            &.toggle {
              cursor: pointer;
              opacity: 0.4;

              &.selected {
                opacity: 1;
              }
            }

            &.small {
              max-width: 40px;
            }

            &.btn {
              cursor: pointer;
              font-size: 12px;
              text-transform: uppercase;
            }
          }

          &.header {
            font-weight: bold;
            background: $lightest;
          }

          &.warning {
            font-weight: bold;
            background: $yield;
            color: $dark;
          }
        }
      }
    }
  }
}
