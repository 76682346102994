@import '../../../styles/shared.scss';

.pin-result-container {
  @extend %flex-col;
  padding: 8px;
  font-family: $font-body;

  .pin-result {
    @extend %flex-col;
    position: relative;
    border: $border-lightest;
    border-radius: 8px;
    width: 240px;
    min-height: 240px;
    padding: 8px;
    height: 100%;
    overflow: hidden;

    .empty-img {
      background: $prettymuchwhite;
      border-radius: 10px;
    }

    img,
    .empty-img {
      width: 160px;
      height: 160px;
      object-fit: contain;
      align-self: center;
    }

    textarea {
      @extend %input-shared;
      margin-top: 8px;
      font-size: 13px;
      padding: 4px 8px;
    }

    .save-btns {
      @extend %flex-row-spread;
      margin-top: 8px;

      .save {
        @extend %btn-dark;
        padding: 4px 16px;
      }

      .cancel {
        @extend %btn-text;
      }
    }

    .metadata {
      @extend %flex-col;
      justify-content: space-between;
      flex: 1;

      .title {
        margin-top: 12px;
        font-size: 14px;
        line-height: 16px;
        font-weight: bold;
      }

      .link {
        margin-top: 2px;
        font-size: 11px;
        cursor: pointer;
      }

      .collection {
        margin-top: 12px;
        font-size: 12px;
        line-height: 14px;
        text-decoration: underline;
        color: $dark;

        &.quick {
          text-decoration: none;
          color: $lighter;
        }
      }

      .name {
        font-size: 12px;
        line-height: 11px;
        font-weight: normal;
        color: $dark;
      }

      .time {
        font-size: 10px;
        line-height: 11px;
        margin-top: 4px;
        color: $dark;
      }
    }

    .action-icons {
      @extend %flex-row-middle;
      position: absolute;
      bottom: 4px;
      right: 4px;

      svg {
        font-size: 12px;
        margin-left: 4px;
        cursor: pointer;
        color: $dark;

        &:hover {
          opacity: 0.6;
        }
      }

      > div {
        margin-left: 4px;
        cursor: default;

        svg {
          pointer-events: none;
        }
      }
    }
  }
}
