@import '../../styles/shared.scss';

.create-invoice-outer-container {
  background: $darker;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 120px;
  color: white;
  padding: 12px 24px;
  box-sizing: border-box;

  .ticket-header {
    font-size: 22px;
    margin-bottom: 16px;
    font-weight: bold;
  }

  .stats-container {
    @extend %flex-row-spread;
    .stat {
      @extend %flex-col-center;
      flex: 1;

      .stat-header {
        font-size: 32px;
        margin-bottom: 4px;
      }
      .stat-label {
        font-size: 14px;
        text-transform: uppercase;
      }
    }
  }

  .action-btns-container {
    @extend %flex-row-middle;
    justify-content: flex-end;
    margin-top: 24px;
    padding-top: 24px;
    border-top: 1px solid $dark;

    .warning-msg {
      font-size: 12px;
      font-weight: bold;
      margin-right: 12px;
    }

    .force-btn {
      @extend %btn-dark;
      padding: 6px 16px;
    }
    .action-btn {
      @extend %btn-light;
      padding: 6px 16px;
      margin-left: 12px;
    }

    .error-msg {
      font-weight: bold;
    }
  }
}
