@import '../../styles/shared.scss';

.login-outer-container {
  @extend %flex-col-center;
  justify-content: center;
  background: $darkest;
  height: 100vh;
  width: 100%;
  color: white;
  font-family: $font-body;

  h1,
  h2 {
    margin: 0;
  }

  h1 {
    font-size: 20px;
  }

  h2 {
    font-size: 16px;
    font-weight: normal;
  }

  form {
    @extend %flex-col;

    input {
      @extend %input-shared;
      width: 40vw;
      max-width: 320px;
      margin-top: 12px;
    }
  }

  .submit-btn {
    @extend %btn-shared;
    margin-top: 12px;
  }
}
