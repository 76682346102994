@import '../../../styles/shared.scss';

.product-search-results-outer-container {
  .product-search-results {
    @extend %flex-row;
    flex-wrap: wrap;
    position: relative;
  }
  .empty-msg {
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    margin-top: 44px;
  }
}
