@import '../../styles/shared.scss';

.pins-outer-container {
  max-width: 2400px;
  width: 90%;
  margin: 80px auto 0;
  font-family: $font-body;

  .pin-results-container {
    @extend %flex-row;
    align-items: stretch;
    position: relative;
    flex-wrap: wrap;
  }
}
