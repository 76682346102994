@import '../../styles/shared.scss';

.waitlist-controls-outer {
  width: 100%;

  .waitlist-controls-inner {
    @extend %flex-row;
    justify-content: space-between;
    width: 100%;
    flex: 1;
    align-items: flex-start;
    margin-top: 10px;


    .waitlist-controls-tab-container {
      @extend %flex-row;
      gap: 10px;

      .tab {
        @extend %flex-row-middle;
        @extend %btn-basic;
        @extend %label;
        align-items: center;
        gap: 10px;
        height: 100%;
        padding: 10px 15px;
        border: $border-lightest;
        border-radius: 5px;
        font-size: 12px;
        color: $darkest;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover {
          border: $border-dark;
        }

        &.active {
          border: $border-dark;
        }
      }

      .search-container input {
        padding: 10px 15px;
        border-radius: 5px;
        border: $border-lightest;
        font-size: 12px;
        color: $darkest;
        width: 200px;
        outline: none;
        border: $border-lightest;
      }
    }

    .create-registration-code-btns {
      @extend %flex-row;

      .create-registration-code-btn {
        @extend %btn-dark;
        margin-left: 12px;

        &.secondary {
          @extend %btn-offwhite;
        }
      }

      .filter-container {
        position: relative;
        background-color: white;

        .filter-label {
          @extend %flex-row;
          @extend %btn-basic;
          @extend %label;
          align-items: center;
          gap: 10px;
          padding: 10px 15px;
          // display: grid;
          // place-items: center;
          border: $border-lightest;
          border-radius: 5px;
          font-size: 12px;
          color: $darkest;
          cursor: pointer;
          transition: all 0.2s ease-in-out;

          &:hover {
            border: $border-dark;
          }

          svg {
            @extend %btn-basic;
            height: 10px;
            width: 10px;
          }
        }

        .filter-dropdown {
          @extend %flex-col;
          gap: 10px;
          position: absolute;
          top: calc(100% + 10px);
          left: 50%;
          transform: translateX(-50%);
          background-color: white;
          border: $border-lightest;
          padding: 10px;
          border-radius: 5px;
          z-index: 2;

          .filter-option {
            @extend %label;
            background-color: white;
            padding: 10px 15px;
            border-radius: 5px;
            font-size: 11px;
            cursor: pointer;
            border: $border-lightest;

            &.active {
              border: $border-dark;
            }
          }
        }
      }
    }

  }
}
