@import '../../styles/shared.scss';

.products-outer-container {
  @extend %flex-row;
  font-family: $font-body;
  box-sizing: border-box;

  .panel {
    @extend %flex-col;
    padding: 12px 16px;
    margin: 24px 8px;
    flex: 1;
    position: relative;
    min-width: 180px;
    max-width: 880px;
    min-height: calc(100vh - 100px);
    max-height: calc(100vh - 100px);
    overflow-y: auto;

    &:not(:last-child) {
      border-right: $border-offwhite;
    }
  }

  .toggle-match-mode-btn {
    @extend %btn-offwhite;
    position: fixed;
    font-size: 11px;
    bottom: 12px;
    right: 12px;
  }
}
