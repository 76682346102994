@import '../../../styles/shared.scss';

.hierarchy-edit-outer-container {
  .radio-button {
    @extend %flex-row-middle;
    cursor: pointer;
    margin: 6px 0;
    justify-content: flex-end;
    font-size: 12px;

    .label {
      margin-right: 6px;
    }

    .radio {
      @extend %btn-radio;
    }
  }

  input,
  textarea {
    margin-bottom: 6px;
  }

  .checkbox-row {
    @extend %flex-row-middle;

    .label {
      margin-left: 4px;
    }
    .subcomment {
      margin-left: 4px;
      font-size: 10px;
      color: $medium;
    }

    input[type='checkbox'] {
      margin-top: 8px;
    }
    input.field {
      padding: 2px 6px;
      margin: 2px 0 0 12px;
      max-width: 24px;
    }
  }

  .action-btns {
    @extend %flex-row;
    justify-content: flex-end;
    margin-top: 16px;

    .action-btn {
      @extend %btn-dark;
      margin-left: 4px;
    }
  }

  .empty-message {
    color: $light;
  }
}
