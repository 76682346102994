@import '../../styles/shared.scss';

.subscription-io-generator-container {
  .select-offers-container {
    padding: 24px;
    background-color: $lightest;

    .groups {
      @extend %flex-col;
      gap: 12px;
      .group {
        .group-title {
          @extend %label;
          font-size: 12px;
          margin-bottom: 4px;
        }
        .offers,
        .bundles {
          @extend %flex-row;
          flex-wrap: wrap;
          gap: 12px;

          .offer,
          .bundle {
            @extend %btn-basic;
            border: $border-dark;
            border-radius: 4px;
            padding: 4px 8px;
            color: $darkest;
            font-size: 12px;

            .title {
              font-size: 12px;
              color: $darkest;
            }

            .description {
              font-size: 10px;
              color: $dark;
            }

            &.selected {
              background-color: $darkest;
              color: white;
              background-color: $darkest;
            }
          }
        }
      }
    }
  }

  .subscription-io {
    background-color: white;
    padding: 24px;
    border-radius: 20px;
    border: $border-darkest;
    max-width: 900px;
    margin-top: 24px;
  }
}
