@import '../../../styles/shared.scss';

.keybinding-container {
  position: fixed;
  bottom: 15px;
  right: 15px;
  padding: 5px;
  border-radius: 5px;
  background: $lightest;
  font-size: 0.7rem;
}

.concierge-sidebar-outer {
  position: fixed;
  top: 10px;
  bottom: 10px;
  right: 10px;
  width: 400px;
  border-radius: 16px;
  border: $border-lightest;
  background-color: $prettymuchwhite;
  padding: 10px;
  box-sizing: border-box;
  overflow-y: scroll;

  .tab-container {
    @extend %flex-row;
    gap: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 1rem;
    border-bottom: $border-lightest;

    .tab {
      font-weight: 600;
      border-radius: 5px;
      border: $border-lightest;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      opacity: 0.3;
      background-color: white;
      font-size: 0.8rem;
      padding: 8px 16px;

      &:hover {
        opacity: 1;
      }

      &.active {
        opacity: 1;
      }
    }
  }
}
