@import '../../../styles/shared.scss';

.concierge-codes-modal-code-outer {

  &+.concierge-codes-modal-code-outer {
    border-top: $border-lightest;
  }

  .concierge-codes-modal-code-inner {
    @extend %flex-row;
    justify-content: space-between;
    padding: 10px;

    &+.concierge-codes-modal-code {
      border-top: $border-lightest;
    }

    .concierge-codes-modal-code-text {
      @extend %flex-col;
      justify-content: center;
      gap: 3px;

      .concierge-codes-modal-code-input {
        font-family: $font-body;
        min-width: 200px;
        font-size: 14px;
        outline: none;
        border: none;
      }

      .concierge-codes-modal-code-subtitle {
        @extend %btn-basic;
        font-size: 12px;
        color: $medium;

        svg {
          margin-left: 5px;
          font-size: 8px;
        }
      }
    }

    .concierge-codes-modal-code-managers {
      @extend %flex-row;
      gap: 10px;
      align-items: center;

      .concierge-codes-select {
        font-size: 10px;
        min-width: 150px;
      }
    }
  }
}
