$border-white: 1px solid $white;
$border-dark: 1px solid $dark;
$border-darker: 1px solid $darker;
$border-darkest: 1px solid $darkest;
$border-medium: 1px solid $medium;
$border-light: 1px solid $light;
$border-lighter: 1px solid $lighter;
$border-lightest: 1px solid $lightest;
$border-offwhite: 1px solid $offwhite;
$border-prettymuchwhite: 1px solid $prettymuchwhite;

%skeleton-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  .skeleton {
    border-radius: 10px;
    border: $border-lightest;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
