@import '../../styles/shared.scss';

.ml-training-similar-brand-outer {
  box-sizing: border-box;
  padding: 10px;
  border-radius: 10px;
  border: $border-lightest;

  .main-container {
    @extend %flex-row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    gap: 10px;

    .brand-name {
      @extend %flex-row;
      align-items: center;
      gap: 5px;
      font-size: 1rem;
      font-weight: 600;
      width: 200px;
      white-space: nowrap;
      overflow: scroll;
    }

    .external-link-icon {
      font-size: 0.6rem;
      color: black;
    }

    .feedback-container {
      @extend %flex-row;
      gap: 5px;
      font-size: 0.8rem;
      color: $medium;
    }

    .rating-container {
      @extend %flex-row;
      gap: 5px;
      align-items: center;

      .rating {
        height: 20px;
        width: 20px;
        border-radius: 50%;
        font-size: 0.7rem;
        background-color: $prettymuchwhite;
        border: $border-lightest;
        display: grid;
        place-content: center;
        cursor: pointer;
      }
    }
  }

  .product-image-container {
    margin-top: 20px;
    @extend %flex-row;
    gap: 10px;

    .product-image {
      height: 60px;
      width: 60px;
      border-radius: 10px;
      object-fit: contain;
      border: $border-lightest;
    }
  }
}
