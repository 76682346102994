@import '../../styles/shared.scss';

.brand-report-sections-outer {
  @extend %flex-row;
  justify-content: center;
  width: 780px;
  height: calc(100vh - 50px);
  overflow-y: scroll;
  padding: 48px 48px;
  scroll-behavior: smooth;
  box-sizing: border-box;

  &::-webkit-scrollbar {
    display: none;
  }

  @include xl {
    width: 900px;
  }

  @include xxl {
    padding: 48px 96px;
  }

  .brand-report-sections-inner {
    .brand-card {
      position: relative;
      margin-bottom: 60px;
      background-color: white;
      max-width: 750px;

      &:last-child {
        padding-bottom: 20px;
      }

      .error-centered {
        height: 100%;
        width: 70%;
        margin: 2rem auto;
        display: grid;
        place-items: center;
        text-align: center;
      }
      .header {
        @extend %flex-row;
        justify-content: space-between;
        gap: 1rem;
        position: relative;

        .description {
          max-width: 350px;

          @include xl {
            max-width: 400px;
          }

          @include xxl {
            max-width: 450px;
          }

          .title {
            font-size: 28px;
            font-family: $font-header;
            font-weight: 600;
            color: $darkest;
            margin-bottom: 15px;
          }

          .sub-title {
            font-size: 12px;
            margin-bottom: 3px;
            color: $dark;
          }
        }

        .brand-card-controls {
          position: absolute;
          bottom: 5px;
          right: 0;
          display: flex;
          gap: 10px;
          text-align: center;
          text-transform: lowercase;

          .rounded-button {
            @extend %btn-basic;
            @extend %flex-row;
            gap: 5px;
            padding: 12px 16px;
            font-size: 12px;
            font-weight: 700;
            color: $black;
            border: $border-darkest;
            box-sizing: border-box;
            white-space: nowrap;
            border-radius: 6px;
            text-transform: uppercase;
          }
        }
      }
    }
  }
}
