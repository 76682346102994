@import '../../styles/shared.scss';

.lookbook-orders-outer {
  .lookbook-orders-inner {
    padding: 50px 0;
    width: 90%;
    margin: 0 auto;

    .lookbook-order-title {
      font-family: $font-header;
      font-size: 3rem;
      font-weight: 500;
      margin-bottom: 1rem;

      .lookbook-orders-updating {
        @extend %label;
        font-size: 0.8rem;
        color: $light;
      }
    }

    .lookbook-orders-container {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
      gap: 1rem;
      transition: opacity 0.3s ease-out;

      &.loading {
        opacity: 0.3;
      }
    }
  }
}
