@import '../../styles/shared.scss';

.chat-outer-container {
  @extend %flex-col;
  font-family: $font-body;
  box-sizing: border-box;

  .chat-inner-container {
    @extend %flex-col;
    margin: 44px auto;
    max-width: 1000px;
    width: 90%;
    align-items: stretch;
  }
}
