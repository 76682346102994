@import '../../styles/shared.scss';

.concierge-summary-outer {
  width: 100%;
  flex: 1;

  .select {
    font-size: 0.7rem;
    color: $dark;
    min-width: 270px;
  }

  .header {
    font-size: 2rem;
    font-weight: 600;
    font-family: $font-header;
    margin-bottom: 10px;
  }

  .summary {
    @extend %flex-row;
    gap: 1rem;
    margin-bottom: 10px;

    .summary-breakdown {
      width: 100%;
    }

    .summary-section-header {
      text-transform: uppercase;
      font-size: 0.6rem;
      color: $light;
      margin-bottom: 5px;
    }

    .fit {
      width: max-content;
    }

    .summary-section-body {
      font-size: 0.7rem;
      font-family: $font-header;
      font-weight: 600;
      color: $dark;
      border-radius: 10px;
      border: $border-lightest;
      overflow: hidden;

      &.health-container {
        @extend %flex-row;
        position: relative;
      }

      .health {
        padding: 10px;
        display: grid;
        place-items: center;
        text-align: center;
        min-width: 60px;
      }
    }
  }
}
