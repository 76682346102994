@import '../../styles/shared.scss';

.brand-report-outer-container {
  position: relative;
  height: 100%;
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;

  .sidebar-section {
    flex: 1;
    background-color: $lightest;
    height: calc(100vh - 50px);
    box-sizing: border-box;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  .main-section {
    &::-webkit-scrollbar {
      display: none;
    }
  }

  .pill {
    position: fixed;
    bottom: 10px;
    right: 10px;
    padding: 5px 10px;
    font-size: 0.8rem;
    border-radius: 100px;
    z-index: 2;
    background-color: white;
    border: $border-dark;
  }

  .select {
    font-size: 0.7rem;
    color: $dark;
  }

  .notes {
    position: fixed;
    right: 15px;
    top: 15px;
    width: 350px;
    background-color: white;
    height: calc(100vh - 30px);
    border-radius: 8px;
    border: $border-darkest;
    box-sizing: border-box;
  }

  .btns {
    @extend %flex-row-middle;
    position: absolute;
    top: 16px;
    right: 16px;

    .btn {
      margin-left: 12px;
    }

    .add-graph-btn {
      @extend %btn-offwhite;
    }
    .download-pdf-btn {
      @extend %btn-darkest;
    }
  }

  .brand-report-elements-selection {
    position: absolute;
    top: 0;
    right: calc(100% + 16px);
    height: 100%;
    min-width: 320px;
  }

  .loading-report {
    @extend %flex-col-middle;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $darkest;

    .loading-message {
      margin-top: -36px;
      color: white;
      font-size: 14px;
      font-weight: bold;
      text-align: center;
      text-transform: uppercase;
    }
  }

  .radio-btns {
    @extend %flex-row-middle;
    flex-wrap: wrap;
    border: $border-lightest;
    border-radius: 6px;
    overflow: hidden;

    .radio-btn {
      cursor: pointer;
      padding: 12px 8px;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 0.1em;
      background: $white;
      color: $dark;
      white-space: nowrap;
      position: relative;
      text-transform: uppercase;

      &:first-child {
        border-radius: 6px 0px 0px 6px;
      }
      &:last-child {
        border-radius: 0px 6px 6px 0px;
      }

      &.active {
        background: $medium;
        color: white;
      }

      &.disabled {
        background: $prettymuchwhite;
        color: $lighter;
      }

      &.changing {
        background: $darkest;
      }

      .loader {
        position: absolute;
        top: -8px;
        left: 0;
        width: 100%;
        height: 44px;
      }
    }
  }
}
