@import '../../../styles/shared.scss';

.concierge-codes-modal-outer {
  position: relative;

  .concierge-codes-modal-inner {
    padding: 20px 0;
    max-height: 600px;
    overflow-y: auto;

    .concierge-codes-modal-title {
      font-size: 36px;
      font-family: $font-header;
      font-weight: 600;
    }

    .concierge-codes-modal-subtitle {
      font-size: 14px;
      margin-bottom: 20px;
    }

    .concierge-codes-modal-search {
      input {
        font-family: $font-body;
        font-size: 12px;
        padding: 8px 12px;
        border: $border-lightest;
        border-radius: 4px;
        outline: none;
        width: 300px;
      }
    }

    .concierge-codes-modal-codes-container {
      @extend %flex-col;
      padding-top: 20px;
      padding-bottom: 100px;

      .concierge-codes-modal-code-skeleton {
        @extend %flex-row;
        justify-content: space-between;

        &+.concierge-codes-modal-code-skeleton {
          border-top: $border-lightest;
          padding: 10px;
          animation: skeleton-fade 3s infinite;

          @for $i from 1 through 100 {
            &:nth-child(#{$i}n) {
              animation-delay: #{$i * .3}s;
            }
          }

          >div {
            @extend %flex-row;
            gap: 10px;

            .concierge-codes-modal-code-skeleton-cell {
              height: 20px;
              width: 100px;
              background-color: $prettymuchwhite;
            }
          }
        }
      }

    }
  }

  .concierge-codes-modal-footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding-top: 15px;
    background-color: $white;
    border-top: $border-lightest;
    justify-content: flex-end;
    @extend %flex-row;
    gap: 10px;

    button {
      @extend %label;
      @extend %btn-basic;
      font-size: 12px;
      padding: 8px 20px;
      border-radius: 4px;
      background-color: $dark;
      color: $white;
      cursor: pointer;
      border: none;
      outline: none;

      &.save {
        background-color: $primary;
      }
    }
  }
}

@keyframes skeleton-fade {
  0% {
    opacity: 1;
  }

  25% {
    opacity: 0.3;
  }

  50% {
    opacity: .6;
  }

  100% {
    opacity: 1;
  }
}
