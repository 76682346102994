@import '../../styles/shared.scss';

.concierge-outer {
  box-sizing: border-box;
  padding: 50px 20px;
  @extend %flex-col;
  gap: 1rem;

  .concierge-header-buttons {
    @extend %flex-row;
    justify-content: flex-end;
    gap: 10px;

    button {
      @extend %label;
      @extend %btn-basic;
      font-size: 12px;
      padding: 8px 20px;
      border-radius: 4px;
      background-color: $offwhite;
      color: $dark;
      cursor: pointer;
      border: none;
      outline: none;

      &.save {
        background-color: $primary;
      }
    }
  }

  .concierge-header {
    @extend %flex-row;
    gap: 50px;
    justify-content: space-between;
  }
}
