@import '../../styles/shared.scss';

.ml-training-similar-brands-outer {
  margin-top: 10px;
  .no-brand-selected {
    margin-top: 20px;
    text-align: center;
  }

  .skeleton-container {
    @extend %flex-row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;

    .skeleton {
      height: 124px;
      width: 362px;
      border-radius: 10px;
      border: $border-lightest;
    }
  }

  .similar-brands-container {
    @extend %flex-row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px;
  }
}
