@import '../../../styles/shared.scss';

.prompt-card-outer-wrapper {
  width: 100%;
  border: 2px solid $lightest;
  border-radius: 10px;
  margin-bottom: 16px;

  .prompt-card-container {
    padding: 10px 40px;

    .prompt-card-header {
      @extend %flex-row-spread;
      margin-bottom: 16px;

      .prompt-card-title {
        @extend %flex-row-middle;
        @extend %label;
        color: $light;
      }

      .prompt-card-actions {
        @extend %flex-row-spread;

        .action-btn {
          @extend %btn-outline-light;
          transition: 0.2s;
          margin-left: 8px;
          transition: all 0.3s ease-in-out;
          min-width: 45px;
          height: 15px;

          &.copy-success {
            color: $success;
          }

          &.save-text {
            color: $success;
          }

          &.cancel-text {
            color: $darkRed;
          }

          &.disabled {
            opacity: 0.5;
            cursor: not-allowed;
          }

          .action-btn-text-icon {
            @extend %flex-row-middle;
            font-size: 20px;
          }
        }
      }
    }

    .prompt-card-body-wrapper {
      .prompt-card-body {
        white-space: pre-wrap;
      }

      .prompt-card-edit-area {
        resize: none;
        width: 100%;
        font: inherit;
        // align bordered edit area with prompt card body
        margin-left: -3px;
        margin-top: -3px;
      }
    }
  }
}
