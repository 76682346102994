@import '../../styles/shared.scss';

.scrapers-outer-container {
  @extend %flex-col;
  font-family: $font-body;
  box-sizing: border-box;
  position: relative;

  .scrapers-inner-container {
    @extend %flex-col;
    margin: 44px auto;
    max-width: 1440px;
    width: 90%;

    .scrapers-action-container {
      margin-bottom: 10px;

      input {
        font-family: $font-body;
        font-size: 12px;
        padding: 8px 12px;
        border: $border-offwhite;
        border-radius: 4px;
        width: 400px;
      }
    }

    .row {
      @extend %flex-row-middle;
      border-bottom: $border-offwhite;
      border-left: $border-offwhite;
      border-right: $border-offwhite;
      padding: 2px 0;
      font-size: 12px;

      &.skeleton-row {
        animation: skeleton-fade 10s infinite;

        @for $i from 1 through 100 {
          &:nth-child(#{$i}n) {
            animation-delay: #{$i * .15}s;
          }
        }
      }

      &.header {
        background: $darker;
        color: $white;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        z-index: 1;

        position: sticky;
        top: 0px;
      }

      &.broken {
        background: $yield;
      }

      &.manual-needs-update {
        background: $yield;
      }

      .cell {
        @extend %flex-row;
        flex: 1;
        margin: 2px 8px;

        svg {
          margin-left: 4px;
        }

        &.skeleton {
          height: 14px;
          background: $offwhite;
          width: 50%;
        }

        &.sm {
          max-width: 50px;
        }

        &.md {
          max-width: 80px;
        }

        &.lg {
          max-width: 200px;
        }

        .btn {
          font-weight: bold;
          cursor: pointer;

          &.on {
            color: $success;
          }

          &.error {
            color: $error;
          }
        }

        .badge {
          justify-content: center;
          font-size: 10px;
          border-radius: 2px;
          background: $darkest;
          font-weight: bold;
          padding: 1px 3px;
          white-space: nowrap;
          color: white;

          &.subscribed {
            opacity: 0.9;
          }

          &.huge {
            opacity: 0.8;
          }

          &.big {
            opacity: 0.7;
          }

          &.medium {
            color: $dark;
            background: $lighter;
          }

          &.small {
            color: $dark;
            background: $lighter;
          }

          &.tiny {
            color: $dark;
            background: $lightest;
          }
        }
      }
    }
  }
}

@keyframes skeleton-fade {
  0% {
    opacity: 1;
  }

  25% {
    opacity: 0.3;
  }

  50% {
    opacity: .6;
  }

  100% {
    opacity: 1;
  }
}
