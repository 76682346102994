@import '../../../styles/shared.scss';

.pin-search-panel-container {
  margin-bottom: 16px;

  form {
    @extend %flex-row-middle-spread;
    widows: 100%;
    input {
      @extend %input-shared;
      width: 100%;
      max-width: 640px;
    }

    .pills {
      @extend %flex-row;
      border-radius: 10px;
      overflow: hidden;

      .pill {
        background: $offwhite;
        padding: 4px 12px;
        cursor: pointer;
        font-size: 15px;
        

        &.active {
          background: $dark;
          color: white;
          font-weight: bold;
      }
      }
    }
  }

  .search-values {
    @extend %flex-row;
    margin-top: 12px;

    .search-value {
      @extend %flex-row-middle;
      margin-right: 12px;
      border: $border-lighter;
      padding: 2px 8px;
      border-radius: 12px;
      font-size: 14px;
      cursor: pointer;

      &:hover {
        opacity: 0.6;
      }

      svg {
        margin-left: 8px;
      }
    }

    .clear-btn {
      align-self: center;
      font-size: 16px;
    }

    .refresh-btn {
      font-size: 12px;
      align-self: center;
      margin-right: 8px;
    }

    svg {
      cursor: pointer;

      &:hover {
        opacity: 0.6;
      }
    }
  }
}
