@import '../../styles/shared.scss';

.consults-outer-container {
  @extend %flex-col;
  font-family: $font-body;
  box-sizing: border-box;

  .consults-inner-container {
    @extend %flex-col;
    margin: 44px auto;
    max-width: 1600px;
    width: 90%;

    .row {
      @extend %flex-row-middle;
      border-bottom: $border-offwhite;
      border-left: $border-offwhite;
      border-right: $border-offwhite;
      padding: 2px 0;
      font-size: 14px;
      font-weight: bold;

      &.header {
        background: $darker;
        color: $white;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;

        .cell {
          color: white;
        }
      }

      &.odd {
        background: $prettymuchwhite;
      }

      &.completed {
        opacity: 0.4;
        font-weight: normal;
      }

      .cell {
        flex: 1;
        padding: 2px 8px;
        white-space: nowrap;
        color: $darker;
        overflow: hidden;

        svg {
          margin-left: 4px;
          font-size: 12px;
        }

        &.small {
          max-width: 24px;
        }

        &.md {
          max-width: 80px;
        }

        &.lg {
          max-width: 200px;
          min-width: 180px;
        }

        &.xl {
          max-width: 400px;
          min-width: 240px;
        }

        &.has-filter {
          cursor: pointer;
        }

        &.btn {
          font-weight: bold;
          cursor: pointer;

          &.on {
            color: $success;
          }

          &:hover {
            opacity: 0.4;
          }

          &.error {
            color: $error;
          }
        }
      }
    }
  }
}
