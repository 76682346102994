@import '../../../styles/shared.scss';

.concierge-comments-outer {
  .select {
    font-size: 0.7rem;
    color: $dark;
    min-width: 270px;
  }

  .header {
    font-size: 2rem;
    font-weight: 600;
    font-family: $font-header;
    margin-bottom: 10px;
  }

  .options {
    @extend %flex-col;
    gap: 10px;

    .search {
      .input {
        padding: 8px 12px;
        font-size: 0.7rem;
        color: $dark;
        border: $border-lighter;
        border-radius: 5px;
        outline: none;
        width: 100%;
        box-sizing: border-box;
      }
    }

    .toggles {
      .analytics-toggle {
        @extend %flex-row;
        gap: 5px;
        font-size: 0.7rem;
        color: $medium;
      }
    }
  }

  .comments-container {
    @extend %flex-row;
    gap: 10px;
    flex-wrap: wrap;
    margin: 20px 0;
  }
}
