@import '../../styles/shared.scss';

.waitlist-priority-tag-modal-outer {
  .close {
    position: absolute;
    right: 20px;
    top: 20px;
    cursor: pointer;
  }

  .waitlist-priority-tag-modal-title {
    font-family: $font-header;
    font-weight: bold;
    letter-spacing: 0.7px;
    font-size: 28px;
    line-height: 36px;
  }

  .waitlist-priority-tag-modal-subtitle {
    font-size: 12px;
    color: $medium;
    margin-bottom: 10px;
  }

  .waitlist-priority-tag-modal-tags,
  .waitlist-priority-tag-modal-add-container {
    @extend %flex-col;
    gap: 10px;
    padding: 1rem 0;

    .waitlist-priority-tag-modal-tag {
      @extend %flex-row;
      padding: 10px 20px;
      border-radius: 5px;
      border: $border-lightest;
      cursor: pointer;
      position: relative;

      >div {
        @extend %flex-row;
        align-items: center;
        gap: 10px;
        width: 100%;
        flex: 1;
        justify-content: space-between;
        font-size: 12px;

        svg {
          height: 12px;
          width: 12px;

          &.isHidden {
            opacity: 0.3;
          }
        }

        .post-count {
          font-size: 12px;
          color: $dark;
          margin-right: 20px;
        }

        input {
          width: 400px;
          font-family: $font-body-new;
          border: none;
          outline: none;
          flex: 1;
        }
      }
    }
  }
}
