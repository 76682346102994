@import '../../../styles/shared.scss';

.product-edit-view-outer-container.panel {
  @extend %flex-col;
  max-width: 540px;

  .cancel.icn {
    position: absolute;
    top: 12px;
    right: 12px;
    font-size: 24px;
    color: $dark;
    cursor: pointer;
  }

  .product-edits {
    @extend %flex-col;

    .available-tags-container {
      @extend %flex-row;
      flex-wrap: wrap;
      margin-bottom: 8px;

      .available-tag {
        @extend %btn-outline-light;
        font-size: 12px;
        margin: 0 6px 6px 0;
        padding: 4px 8px;
        border-radius: 12px;

        &.active {
          background: $dark;
          color: $white;
        }
      }
    }

    .select {
      margin-bottom: 4px;
    }

    .input-row {
      @extend %flex-row-middle;

      .icon {
        margin-left: 6px;
        cursor: pointer;
        color: $dark;
        font-size: 12px;
      }

      &.multi {
        input:last-child {
          max-width: 80px;
        }
      }
    }

    .checkbox-row {
      @extend %flex-row-middle;
      justify-content: flex-end;

      .label {
        margin-right: 4px;
        font-size: 12px;
      }

      input {
        margin-top: 6px;
        flex: 0;
      }
    }

    input,
    textarea {
      flex: 1;
      @extend %input-shared;
      margin-bottom: 4px;
    }
    .submit-btns {
      @extend %flex-row-middle;
      justify-content: flex-end;
      margin-top: 12px;

      .icn {
        color: $dark;
        margin: 0 12px;
      }

      .btn {
        @extend %btn-dark;
        margin-left: 6px;
      }
    }
  }

  .variants-list,
  .pins-list {
    border-top: $border-offwhite;
    padding-top: 24px;
    margin-top: 24px;
    order: 2;

    &.top-section {
      order: 1;
    }

    .variants-header,
    .pins-header {
      @extend %flex-row-middle-spread;
      font-size: 18px;
      font-weight: bold;

      .main {
      }
      .make-match-btn {
        @extend %btn-success;
      }
    }

    .empty-msg {
      background: $yield;
      border: $border-offwhite;
      padding: 48px 0;
      text-align: center;
      width: 100%;
      margin-top: 12px;
    }

    .variants-container,
    .pins-container {
      @extend %flex-row;
      align-items: stretch;
      flex-wrap: wrap;

      .variant-container,
      .pin-container {
        @extend %flex-col;
        justify-content: space-between;
        padding: 12px;
        margin: 8px;
        border: $border-offwhite;
        border-radius: 4px;
        max-width: 132px;

        img {
          width: 120px;
          height: 120px;
          min-width: 120px;
          min-height: 120px;
          object-fit: contain;
          align-self: center;
          margin-bottom: 4px;
        }

        .retailer,
        .title {
          color: $dark;
          text-decoration: none;
          font-weight: bold;
          font-size: 14px;
          line-height: 15px;
        }

        .raw-title {
          color: $dark;
          font-weight: normal;
          font-size: 10px;
          line-height: 11px;
          margin-top: 2px;
        }

        .link {
          color: $dark;
          text-decoration: none;
          font-weight: bold;
          font-size: 12px;
        }

        .price {
          font-size: 12px;
        }

        .controls {
          border-top: $border-offwhite;
          padding-top: 12px;
          margin-top: 12px;

          .control {
            @extend %btn-offwhite;
            padding: 4px 12px;
            font-size: 12px;

            &:not(:last-child) {
              margin-bottom: 6px;
            }
          }
        }
      }
    }
  }
}
