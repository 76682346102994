%select-shared {
  border-radius: 4px;
  outline: none;
  border: $border-lightest;
  font-family: $font-body;
  font-size: 12px;
  background: transparent;

  option {
    font-family: $font-body;
  }
}
