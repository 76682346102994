@import '../../styles/shared.scss';

.main-container-outer-container {
  width: 100%;

  > .header {
    @extend %flex-row;
    padding: 12px 0;
    background: $darkest;
    color: white;
    padding-left: 8px;
    overflow-x: auto;

    @include lg {
      padding-left: 36px;
      overflow-x: auto;
    }

    a {
      text-decoration: none;
      padding: 4px 4px;
      color: white;
      font-weight: bold;
      font-size: 9px;
      white-space: nowrap;

      @include md {
        font-size: 14px;
        padding: 4px 12px;
      }
    }
  }
}

// Custom styling for cogo-toast
.ct-toast {
  font-family: $font-body !important;
  min-height: 36px !important;
  color: $darkest !important;
}

// Custom styling for confirm alert
.react-confirm-alert-body {
  font-family: $font-body !important;
  color: $darker !important;
  box-shadow: $shadow-light !important;
}

.react-confirm-alert-overlay {
  animation: react-confirm-alert-fadeIn 0.25s 0.1s forwards !important;
  background: rgba(0, 0, 0, 0.7) !important;
}
