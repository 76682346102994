@import '../../styles/shared.scss';

.concierge-talent-result-outer {
  border-radius: 16px;
  border: $border-lightest;
  width: 550px;

  .close-button {
    position: absolute;
    top: 10px;
    right: 20px;
    cursor: pointer;
  }

  &.important {
    border: $border-dark;
  }

  .concierge-default-panel {
    @extend %flex-row;
    // overflow: hidden;
    position: relative;
    height: 100%;

    .notification-container {
      position: absolute;
      top: 0;
      right: 0;
      width: 25px;
      height: 25px;
      border-radius: 50%;
      background-color: $dark;
      color: $prettymuchwhite;
      display: grid;
      place-items: center;
      font-size: 0.7rem;
      font-weight: 600;
      font-style: italic;
      transform: translate(50%, -50%);
      cursor: pointer;
    }

    .image-container {
      position: relative;
      height: 100%;
      aspect-ratio: 1/1;
      display: grid;
      place-items: center;
      max-width: 190px;

      .concierge-image {
        height: 100px;
        width: 100px;
        margin: 0px;
        padding: 0px;
        border-radius: 10px;
        object-fit: cover;
      }

      .concierge-image-replacement {
        display: grid;
        place-items: center;
        height: 100px;
        width: 100px;
        margin: 0px;
        padding: 0px;
        font-size: 4rem;
        font-weight: 600;
        font-family: $font-header;
        background-color: black;
        color: white;
        border-radius: 10px;
      }
    }

    .content-container {
      @extend %flex-row;
      justify-content: space-between;
      width: 100%;
      gap: 1rem;
      padding: 10px;
      flex: 1;
      overflow: hidden;

      .profile-info {
        display: flex;
        place-items: center;
        gap: 10px;
        max-width: 200px;
        overflow: scroll;
      }

      .attribute-container {
        @extend %flex-col;
        justify-content: center;
        gap: 5px;

        .attribute {
          @extend %flex-row;
          justify-content: space-between;
          box-sizing: border-box;
          padding: 5px 10px;
          gap: 30px;
          border-radius: 5px;
          font-size: 0.7rem;
          cursor: pointer;

          .attribute-text {
            @extend %flex-row;
            justify-items: center;
            gap: 5px;
            font-weight: 600;

            span {
              margin-left: 10px;
            }
          }

          .attribute-value {
            font-weight: 600;
            font-style: italic;
            color: $dark;
          }
        }
      }

      .name {
        font-size: 1rem;
        font-family: $font-header;
        font-weight: 600;
        margin: 0px;
        padding: 0px;
        cursor: pointer;

        .shop-link-icon {
          margin-left: 10px;
        }
      }

      .manager {
        font-size: 0.6rem;
        color: $dark;
        font-weight: 600;
        font-style: italic;
      }

      .tiny {
        font-size: 0.6rem;
        color: $dark;
        text-decoration: none;
      }

      .copyable {
        cursor: pointer;
        transition: color 0.5s ease;

        &:hover {
          color: black;
        }

        svg {
          margin-left: 5px;
        }
      }

      .cell {
        padding: 5px 25px;
        border: $border-lightest;
        border-radius: 10px;
        text-align: center;
        font-size: 0.8rem;
        white-space: nowrap;

        + .cell {
          margin-top: 5px;
        }
      }

      .spacer {
        padding: 5px;
      }

      .clickable {
        cursor: pointer;
        transition: color 0.5s ease;

        &:hover {
          color: black;
        }
      }
    }
  }

  .concierge-score-breakdown-panel {
    padding: 10px;
    box-sizing: border-box;
    position: relative;

    .user-details {
      @extend %flex-row;
      align-items: center;
      justify-content: center;
      gap: 5px;
      margin-bottom: 5px;

      .name {
        font-size: 1rem;
        font-family: $font-header;
        font-weight: 600;
        margin: 0px;
        padding: 0px;
      }

      .shop-link {
        font-size: 0.6rem;
        color: $dark;
        text-decoration: none;
      }
    }

    .stats {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      gap: 5px;

      .stat-container {
        @extend %flex-col;
        gap: 5px;

        .stat {
          @extend %flex-row;
          justify-content: space-between;
          align-items: center;
          gap: 20px;
          padding: 5px 10px;
          font-size: 0.7rem;
          cursor: pointer;
          border: $border-lightest;
          border-radius: 8px;

          .stat-text {
            @extend %flex-row;
            justify-items: center;
            gap: 5px;
            font-weight: 600;

            .edit-icon {
              font-size: 0.6rem;
            }
          }

          .stat-value {
            font-weight: 600;
            font-style: italic;
            color: $dark;
            font-size: 0.6rem;
          }
        }
      }
    }
  }

  .concierge-comment-panel {
    @extend %flex-col;
    gap: 5px;
    position: relative;
    box-sizing: border-box;
    max-height: 150px;
    overflow-y: scroll;
    padding: 10px;

    .user-details {
      @extend %flex-row;
      gap: 15px;
      justify-content: center;
      align-items: center;
      margin-left: 5px;

      svg {
        height: 10px;
        cursor: pointer;
      }

      .name {
        font-size: 1rem;
        font-family: $font-header;
        font-weight: 600;
        margin: 0px;
        padding: 0px;
      }

      .shop-link {
        font-size: 0.6rem;
        color: $dark;
        text-decoration: none;
      }
    }

    .comment {
      @extend %flex-row;
      justify-content: space-between;
      padding: 5px;
      position: relative;
      border: $border-lightest;
      border-radius: 8px;

      &.isRead {
        opacity: 0.5;
        text-decoration: line-through;
      }

      &.add-comment {
        display: grid;
        place-content: center;
        cursor: pointer;
      }

      .comment-text {
        cursor: pointer;
        font-size: 0.8rem;
        margin-bottom: 2px;
      }

      .comment-author {
        font-size: 0.6rem;
        color: $medium;
      }

      .comment-buttons {
        display: grid;
        place-content: center;

        .checkbox {
          width: 10px;
          height: 10px;
          border: $border-lighter;
          border-radius: 2px;
          margin-right: 4px;
          cursor: pointer;

          &.checked {
            background: $dark;
          }
        }
      }
    }
  }
}
