@import '../../styles/shared.scss';

.subscription-bundles-container {
  .cell {
    .offers {
      @extend %flex-row-middle;
      gap: 8px;
      flex-wrap: wrap;
      max-width: 800px;

      .offer {
        @extend %flex-row-middle;
        gap: 8px;
        background-color: $darkest;
        padding: 2px 4px;
        border-radius: 4px;
        color: white;
        font-weight: bold;

        svg {
          @extend %btn-basic;
        }
      }
    }
  }
}
