$primary: #11835a; // Emerald Green
$primaryDark: #186042; // Forest (Darker) Green
$secondary: #c6e9e4;
$secondary-dark: #5ad4bd;
$secondary-md: #9bd7ce;
$tertiary: #f4c0ff; // faded pink
$tertiary-light: #f4e1fb; // lighter faded pink
$gold: #ffd300;
$silver: #c0c0c0;
$bronze: #cd7f32;
$black: black;
$white: white;
$whynotusewhite: #fcfcfc;
$prettymuchwhite: #f7f7f7;
$offwhite: #f2f2f2;
$lightest: #e5e5e5;
$lighter: #cccccc;
$light: #9c9a9c;
$medium: #767676;
$dark: #4a4a4a;
$darker: #333;
$darkest: #171919;
$error: #d30000;
$success: #96b603;
$yield: #eed20288;
$fb-blue: #4267b2;
$twitter-blue: #1da1f2;

// Green Colors
$lightestGreen: #dcf6ea;
$lighterGreen: #9fdabd;
$lightGreen: #61be91;
$green: #11835a;
$darkGreen: #186042;
$darkerGreen: #0b472e;

// Red Colors
$lightestRed: #f5d0d0;
$lighterRed: #ffbebe;
$lightRed: #fd9d9d;
$red: #fe7d7d;
$darkRed: #ec4040;
$darkerRed: #c72a2a;

// Health Scale
$highest: #0fc100;
$higher: #39b92e;
$high: #74b36f;
$middle: #9fad9e;
$low: #ad7b7b;
$lower: #ab4141;
$lowest: #ab0e0e;

// Employees
$harry: #e5e7e9;
$chris: #aeb6bf;

// Growth
$rachel: #f5cba7;
$austin: #f5b7b1;

// Engagement
$kellee: #a9dfbf;
$em: #d7bde2;

$tiffany: #a9cce3;
$leslie: #f9e79f;
