@import '../../styles/shared.scss';

.lookbook-orders-controls-outer {
  .lookbook-orders-controls-inner {
    @extend %flex-row;
    justify-content: space-between;
    padding: 1rem 0;

    .select {
      font-size: 0.7rem;
      color: $dark;
      min-width: 270px;
    }

    .lookbook-order-tab-container {
      @extend %flex-row;
      gap: 0.5rem;
      position: relative;

      .lookbook-order-tab {
        @extend %label;
        height: 100%;
        border-radius: 0;
        font-size: 12px;
        color: $darkest;
        border-bottom: 4px solid transparent;
        transition: all 0.2s ease-in-out;
        font-weight: bold;

        // @include sm-down {
        //   font-size: 10px;
        // }

        &.active {
          border-bottom: 4px solid $primary;
          color: $darkest;

          // @include xs-down {
          //   border-bottom: none;
          // }
        }

        &.active > span {
          background-color: $prettymuchwhite;

          // @include xs-down {
          //   background-color: $offwhite;
          // }
        }

        span {
          @extend %flex-row-middle;
          padding: 8px 12px;
          border-radius: 4px;
          margin-bottom: 8px;
          cursor: pointer;
          border: $border-offwhite;
          transition: all 0.2s ease-in-out;

          &:hover {
            border: $border-dark;
          }
        }
      }
    }
  }
}
