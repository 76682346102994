@import '../../../styles/shared.scss';

.tag-gpt-modal-content {
  .checkbox {
    width: 10px;
    height: 10px;
    border: $border-lighter;
    border-radius: 2px;

    &.checked {
      background: $dark;
    }
  }

  .field-title {
    color: $medium;
    font-weight: 600;
    margin: 6px 0;
  }

  .field-row {
    @extend %flex-row-middle;
    gap: 6px;

    > input {
      margin: 0;
      cursor: pointer;
    }
  }

  .modal-explainer-textarea {
    resize: none;
    width: 100%;
    height: 75px;
    font: inherit;
  }

  .action-btn-cancel {
    @extend %btn-outline;
    transition: 0.2s;
    margin-top: 32px;
    width: 100%;
    font-size: 16px;
  }

  .action-btn-save {
    @extend %btn-dark;
    transition: 0.2s;
    margin-top: 32px;
    width: 100%;
    font-size: 16px;

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }

  .modal-footer {
    @extend %flex-row-middle;
    justify-content: flex-end;
    gap: 16px;
  }
}
