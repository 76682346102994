@import '../../styles/shared.scss';

.ml-training-controls-outer {
  margin: 1rem 0;

  .training-controls {
    @extend %flex-row;
    gap: 1rem;
    justify-content: center;

    .select {
      font-size: 0.7rem;
      color: $dark;
      min-width: 270px;
      outline: none;
    }

    .arrows {
      @extend %flex-row;
      align-items: center;
      font-size: 0.6rem;
      cursor: pointer;
    }
  }
}
