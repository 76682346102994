@import '../../../styles/shared.scss';

.header-information {
  @extend %flex-col;
  gap: 3rem;

  .header-section {
    display: grid;
    place-items: center;

    img.logo {
      height: 140px;
      aspect-ratio: 1;
      object-fit: contain;
      border: $border-lightest;
      padding: 10px;
      border-radius: 10px;
    }

    .title {
      font-size: 36px;
      font-weight: 700;
      font-family: $font-header;
    }

    .brand-info-container {
      background-color: $offwhite;
      box-sizing: border-box;
      padding: 32px;
      width: 100%;
      border-radius: 16px;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 10px;

      .brand-info {
        cursor: pointer;
        white-space: nowrap;
        font-size: 14px;
        font-family: $font-body;
      }
    }
  }
}
