@import '../../styles/shared.scss';

.brand-reports-outer-container {
  @extend %flex-center;
  font-family: $font-body;

  .brand-reports-inner-container {
    @extend %flex-col;
    width: 100%;

    .header-container {
      @extend %flex-row-middle-spread;
      width: 80%;
      margin: 0 auto;
      display: none;

      .main {
        @extend %flex-row-middle;
        flex: 1;
        margin-right: 24px;

        .label {
          @extend %header-fancy-sm;
          margin-right: 24px;
        }
        > .select {
          flex: 1;
          max-width: 640px;
        }
      }
      .secondary {
        @extend %flex-row-middle;

        .label {
          @extend %header-fancy-sm;
          margin-right: 24px;
        }
        > .select {
          flex: 1;
          min-width: 240px;
        }
      }
    }
  }
}
