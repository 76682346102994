@import '../../styles/shared.scss';

.prompts-outer-container {
  @extend %flex-row;
  padding: 40px 40px 10px;

  .prompt-options-container {
    width: 25%;
    margin-right: 32px;

    .prompt-option-select {
      margin-bottom: 16px;

      .prompt-option-select__control {
        border: none;
      }
    }

    .action-btn {
      @extend %btn-dark;
      transition: 0.2s;
      margin-top: 32px;
      width: 100%;
      font-size: 16px;
    }
  }

  .prompt-display {
    width: 75%;
    @extend %flex-col-center;

    .prompt-card-container {
      margin-bottom: 24px;
    }

    .loading-container {
      @extend %skeleton-container;

      .skeleton {
        height: 300px;
        width: 100%;
      }
    }
  }
}
