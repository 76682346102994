@import '../../styles/shared.scss';

.greenhouse-outer {
  background: $prettymuchwhite;
  min-height: 100vh;

  .greenhouse-inner {
    padding: 100px 0;
    width: min(90%, 1200px);
    margin: 0 auto;

    .greenhouse-button {
      @extend %flex-row;
      @extend %btn-basic;
      @extend %label;
      gap: 10px;
      padding: 8px 16px;
      border-radius: 4px;
      font-size: 12px;
      color: white;
      background: $darkest;
      border: none;
      outline: none;
      height: max-content;
      width: max-content;
      align-self: flex-end;
      transition: opacity .2s ease-out;

      &.disabled {
        pointer-events: none;
        opacity: .3;
      }
    }

    .greenhouse-header {
      @extend %flex-row;
      justify-content: space-between;

      .greenhouse-title {
        @extend %flex-row;
        gap: 20px;
        align-items: center;
        font-family: $font-header;
        font-size: 36px;
        font-weight: 600;
      }

      .greenhouse-controls {
        @extend %flex-row;
        gap: 10px;
        align-items: flex-end;
      }
    }

    .greenhouse-content {
      display: grid;
      grid-template-columns: 300px 1fr;
      gap: 40px;
      margin: 30px 0;

      .selected-job-placeholder {
        @extend %label;
        color: $medium;
        font-size: 12px;
      }

      .greenhouse-job-listings {
        @extend %flex-col;
        gap: 10px;

        .greenhouse-job-listing-preview {
          @extend %flex-col;
          padding: 15px;
          border-radius: 10px;
          background: white;
          justify-content: center;
          width: 300px;
          opacity: .3;
          transition: opacity .2s ease-out;

          &.selected {
            opacity: 1;
          }

          &:hover {
            cursor: pointer;
            opacity: .7;
          }

          .greenhouse-job-listing-preview-title {
            font-size: 14px;
            font-weight: 600;
            text-wrap: wrap;

            svg {
              height: 10px;
              width: 10px;
              margin-left: 5px;
            }
          }

          .greenhouse-job-listing-preview-department {
            font-size: 12px;
            color: $medium;
          }
        }
      }


      .greenhouse-skeleton-container {
        @extend %flex-col;
        gap: 10px;
        flex: 1;

        .greenhouse-skeleton {
          @extend %flex-col;
          gap: 5px;
          padding: 10px;
          border-radius: 4px;
          background: white;
          height: 50px;
          width: 100%;

          .greenhouse-skeleton-title {
            height: 20px;
            width: 70%;
            background: $offwhite;
          }

          .greenhouse-skeleton-department {
            height: 20px;
            width: 40%;
            background: $offwhite;
          }
        }
      }
    }
  }
}
