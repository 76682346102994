@import '../../../styles/shared.scss';

.concierge-comment-outer {
  border: $border-lightest;
  border-radius: 16px;
  padding: 20px;
  height: max-content;
  position: relative;
  cursor: default;
  background-color: white;
  width: 100%;
  box-sizing: border-box;

  &:hover {
    .comment-icons {
      opacity: 1;
    }
  }

  .comment-icons {
    @extend %flex-row;
    gap: 5px;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(0, -7px);
    opacity: 0;
    transition: opacity 0.2s ease-out;

    .comment-icon {
      display: grid;
      place-content: center;
      height: 10px;
      width: 10px;
      border-radius: 50%;
      background-color: $offwhite;
      border: $border-lightest;
      font-size: 0.6rem;
      cursor: pointer;
      padding: 5px;

      &.isRead {
        background-color: $dark;
        color: $prettymuchwhite;
      }
    }
  }

  .concierge-comment-inner {
    height: max-content;

    &.isRead {
      opacity: 0.3;
    }

    .comment {
      font-size: 0.9rem;
      font-family: $font-body;
      color: $dark;

      .comment-text {
        margin-bottom: 10px;
        cursor: pointer;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;

        &:hover {
          -webkit-line-clamp: 100;
        }
      }

      .authors {
        font-size: 0.6rem;
        color: $medium;
      }

      .clickable {
        transition: all 0.5s ease-out;
        cursor: pointer;

        &:hover {
          color: black;
        }
      }
    }

    .buttons {
      @extend %flex-row;
      align-items: center;
      gap: 10px;

      .button {
        background-color: $offwhite;
        border-radius: 50%;
        padding: 10px;
        display: grid;
        place-items: center;
        cursor: pointer;
      }
    }
  }
}
