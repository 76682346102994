@import '../../styles/shared.scss';

.selection-option-outer-container {
  padding: 4px 12px;
  cursor: pointer;
  border-top: $border-white;
  border-bottom: $border-white;

  &.disabled {
    pointer-events: none;
    opacity: 0.3;
  }

  &:hover {
    color: $darkest !important;
    background: $prettymuchwhite;
    border-top: $border-offwhite;
    border-bottom: $border-offwhite;
  }

  .title {
    font-size: 12px;
    color: $darkest;
    font-weight: bold;
    text-transform: inherit;
    white-space: pre-line;
  }
  .subtitle {
    font-size: 10px;
    color: $medium;
    font-weight: normal;
    text-transform: normal;
    white-space: pre-line;
  }
}
