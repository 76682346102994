@import '../../styles/shared.scss';

.merchants-outer-container {
  @extend %flex-col;
  font-family: $font-body;
  box-sizing: border-box;
  position: relative;

  &.tagging {
    padding-right: 280px;
  }

  .merchants-inner-container {
    @extend %flex-col;
    margin: 44px auto 400px; // To give room for editing
    max-width: 1440px;
    width: 80%;

    .header-container {
      @extend %flex-row-middle-spread;
      margin-bottom: 24px;

      .search-container {
        @extend %flex-row;
        flex: 1;

        input {
          @extend %input-shared;
          flex: 1;
          max-width: 440px;
        }
      }
      .mode-selector {
        @extend %flex-row-middle;
        .mode-toggle {
          @extend %btn-offwhite;
          padding: 4px 8px;
          margin-left: 8px;

          &.active {
            @extend %btn-dark;
          }
        }
      }
    }

    .merchant-results {
      transition: opacity 0.2 ease-in-out;

      &.fetching {
        opacity: 0.25;
      }
    }

    .row {
      @extend %flex-row-middle;
      border-bottom: $border-offwhite;
      border-left: $border-offwhite;
      border-right: $border-offwhite;
      padding: 2px 0;
      font-size: 14px;

      &.header {
        background: $darker;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;
        color: $white;
      }

      &.faded {
        background: $prettymuchwhite;
      }

      &.highlighted {
        background: $offwhite;
      }

      &.success {
      }

      &.fail {
        background: #ff00003f;
      }

      &.warn {
        background: #ffe4003f;
      }

      .cell {
        flex: 1;
        padding: 2px 8px;

        &.xs {
          max-width: 24px;
        }
        &.sm {
          max-width: 60px;
        }

        &.md {
          max-width: 80px;
        }

        &.lg {
          max-width: 160px;
        }

        &.xl {
          max-width: 320px;
        }

        &.clickable {
          cursor: pointer;
          &:hover {
            opacity: 0.8;
          }
        }

        .lock-icn {
          margin-right: 4px;
          font-size: 11px;
          color: $lightest;

          &.locked {
            color: $darkest;
          }
        }

        .incorrect-value {
          text-decoration: line-through;
          opacity: 0.3;
        }

        .correct-value {
          margin-left: 8px;
          font-weight: bold;
        }

        input {
          @extend %input-shared;
        }

        &.tags {
          @extend %flex-row;
          flex-wrap: wrap;
          .tag {
            @extend %btn-basic;
            font-size: 10px;
            border-radius: 2px;
            background: $dark;
            font-weight: bold;
            padding: 1px 3px;
            color: white;
            margin: 0 0 2px 2px;

            &.selected {
              background: $success;
              color: $white;
            }

            svg {
              margin-left: 2px;
              font-size: 8px;
            }
          }
        }

        .checkbox {
          @extend %btn-basic;
          width: 10px;
          min-width: 10px;
          height: 10px;
          min-height: 10px;
          border: $border-lighter;
          border-radius: 2px;
          margin-right: 4px;

          &.checked {
            background: $dark;
          }

          &.disabled {
            opacity: 0.2;
            cursor: default;
          }
        }
      }
    }
  }
}
