@import '../../styles/shared.scss';

.selected-job-outer {
  .selected-job-inner {
    @extend %flex-col;
    gap: 10px;
    flex: 1;

    .selected-job-slack-user-select {
      font-size: 12px;
      width: 350px;
    }

    .selected-job-header {
      @extend %flex-row;
      justify-content: space-between;
      margin-bottom: 30px;

      .selected-job-title {
        font-size: 24px;
        font-weight: 600;
      }

      .selected-job-subtitle {
        font-size: 14px;
        color: $medium;
      }

      >div {
        @extend %flex-col;
        gap: 10px;
      }


      .selected-job-smart-recruiting-toggle-container {
        @extend %label;
        font-size: 0.8rem;
        font-weight: 600;
        @extend %flex-row;
        align-items: center;
        gap: 12px;
        justify-content: flex-end;
        position: relative;
        z-index: 0;

        &.beginning {
          justify-content: flex-start;
        }

        svg {
          height: 10px;
          width: 10px;
          position: absolute;
          top: 50%;
          right: -100%;
          z-index: 1000;
          transform: translate(-65%, -50%);
        }
      }
    }

    .selected-job-section {
      margin: 30px 0;

      .selected-job-configuration-container {
        margin: 10px 0;

        .selected-job-configuration-title {
          @extend %flex-row;
          align-items: center;
          font-size: 18px;
          font-weight: 600;
          margin-bottom: 5px;
        }

        svg {
          height: 10px;
          width: 10px;
          color: $medium;
        }
      }

      .selected-job-smart-recruiting-settings {
        @extend %flex-col;
        gap: 30px;

        .selected-job-smart-recruiting-settings-section-header {
          @extend %flex-row;
          justify-content: space-between;

          .selected-job-smart-recruiting-settings-section-title {
            font-size: 18px;
            font-weight: 600;
          }

          .selected-job-smart-recruiting-settings-section-subtitle {
            font-size: 14px;
            color: $medium;
            margin-bottom: 10px;
          }

          .selected-job-smart-recruiting-settings-section-select {
            font-size: 12px;
            width: 200px;
          }
        }

        textarea {
          width: 100%;
          padding: 10px;
          border-radius: 4px;
          border: 1px solid $light;
          outline: none;
          resize: vertical;
          font-family: $font-body-new;
          font-size: 14px;
          min-height: 100px;
        }
      }
    }
  }
}
