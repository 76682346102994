@import '../../styles/shared.scss';

.lookbook-order-outer {
  .lookbook-order-inner {
    padding: 30px;
    border: $border-lightest;
    border-radius: 16px;

    .select {
      font-size: 0.7rem;
      color: $dark;
      // width: 200px;
      font-family: $font-body-new;
      box-sizing: border-box;
    }

    .lookbook-order-header {
      margin-bottom: 10px;
      @extend %flex-row;
      justify-content: space-between;

      .title {
        @extend %flex-row;
        font-family: $font-header;
        font-size: 1.3rem;
        font-weight: 500;
        margin-bottom: 5px;

        span a {
          color: black;
          text-decoration: none;
          @extend %flex-row;
          font-size: 0.7rem;
        }
      }

      .subtitle {
        @extend %flex-row;
        font-size: 0.7rem;
        margin-bottom: 5px;

        span a {
          color: black;
        }
      }
    }

    .account-manager-container {
      margin-bottom: 1rem;
    }

    .item-container {
      @extend %flex-row;
      gap: 1rem;
      overflow-x: scroll;
      padding-bottom: 1rem;

      .item {
        height: 200px;
        min-width: 200px;
        position: relative;
        color: white;
        @extend %flex-col;
        justify-content: flex-end;
        text-decoration: none;

        .item-image {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 100%;
          border-radius: 10px;
          overflow: hidden;

          img {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }

          .image-overlay {
            height: 100%;
            width: 100%;
            background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
          }
        }

        .item-info {
          position: relative;
          z-index: 2;
          padding: 10px;

          .item-title {
            font-family: $font-header;
            font-size: 1rem;
            margin-bottom: 5px;
          }

          .item-subtitle {
            font-size: 0.7rem;
          }
        }
      }
    }

    .shipping-information-container {
      display: grid;
      grid-template-columns: 2fr 1fr;
      gap: 1rem;

      .order-details-container {
        flex: 1;
        .order-detail {
          @extend %flex-col;
          gap: 5px;
          margin-bottom: 1rem;

          .order-detail-label {
            font-size: 0.7rem;
            color: $light;
          }

          .order-detail-value {
            font-size: 0.7rem;
            color: $dark;
          }

          .save-button {
            @extend %btn-basic;
            outline: none;
            border: $border-lightest;
            border-radius: 5px;
            padding: 10px 15px;
          }
        }
      }

      .order-input-container {
        @extend %flex-row;
        gap: 1rem;
        flex-wrap: wrap;
        flex: 1;

        .order-input {
          width: 100%;
          .order-input-label {
            font-size: 0.7rem;
            color: $light;
            margin-bottom: 5px;
          }

          .select {
            font-size: 0.7rem;
            color: $dark;
            // width: 200px;
            font-family: $font-body-new;
            box-sizing: border-box;
          }

          input {
            border: $border-lightest;
            outline: none;
            font-family: $font-body-new;
            font-size: 0.7rem;
            border-radius: 5px;
            padding: 11px 15px;
            min-width: 100%;
            box-sizing: border-box;
          }

          textarea {
            border: $border-lightest;
            outline: none;
            font-family: $font-body-new;
            font-size: 0.7rem;
            border-radius: 5px;
            padding: 10px 15px;
            min-width: 100%;
            box-sizing: border-box;
          }
        }
      }
    }
  }
}
