@import '../../../styles/shared.scss';

.queue-ticket-container {
  border: $border-offwhite;
  padding: 12px;
  border-radius: 12px;
  min-width: 340px;
  margin-left: 12px;
  background: white;
  position: relative;

  &:first-child {
    margin-left: 32px;
  }

  &.selected {
    border: 1px solid $light;
    box-shadow: $shadow-dark;
  }

  .ticket-header {
    display: none;
  }

  .queue-ticket {
    @extend %flex-row-middle;

    img {
      width: 120px;
      height: 120px;
      align-self: center;
      margin-right: 8px;
      object-fit: contain;
    }

    .product-data {
      @extend %flex-col;

      .stats {
        @extend %label;
        font-size: 9px;
        font-weight: normal;
        white-space: inherit;
      }

      svg {
        font-size: 14px;
        margin-left: 6px;
        cursor: pointer;

        &:hover {
          opacity: 0.6;
        }
      }

      .title {
        font-size: 16px;
        font-weight: bold;
      }
      .brand {
        svg {
          font-size: 12px;
        }
      }

      .retailer {
        color: $dark;
      }
      .link {
        color: $dark;
        font-size: 12px;
      }

      .admin-notes {
        color: $dark;
        font-size: 9px;
        margin-top: 4px;
      }
    }
  }

  .back-to-basic-btn {
    position: absolute;
    bottom: 4px;
    left: 4px;
    font-size: 12px;
    color: $dark;
    cursor: pointer;
  }

  .orphan-btn {
    position: absolute;
    bottom: 4px;
    right: 4px;
    font-size: 12px;
    color: $dark;

    cursor: pointer;
  }
}
