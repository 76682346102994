@import '../../styles/shared.scss';

.ml-outer-container {
  @extend %flex-col;
  font-family: $font-body;
  box-sizing: border-box;

  .ml-inner-container {
    @extend %flex-col;
    margin: 64px auto 44px;
    max-width: 1024px;
    width: 90%;
  }
}
