@import '../../../styles/shared.scss';

.products-search-view-outer-container {
  position: relative;
  
  .search-input-container {
    @extend %flex-row;
    position: relative;
    z-index: 2;

    .search-input {
      @extend %input-inline;
      margin-bottom: 12px;
      font-size: 16px;
      flex: 1;
    }

    .search-clear-btn {
      position: absolute;
      right: 4px;
      top: 4px;
      cursor: pointer;
    }
  }

  .best-in-header {
    font-size: 20px;
    font-weight: bold;
    padding: 12px 0;
    color: $darkest;
    display: none;
  }

  .products-search-view-container {
    transition: all 0.25s ease-in-out;

    &.searching {
      opacity: 0.3;
    }

    .searching-overlay {
      @extend %flex-center;
      position: absolute;
      width: 100%;
      height: 240px;
      pointer-events: none;
      transition: all 0.2s ease-in-out;

      &.hidden {
        opacity: 0;
      }
    }
  }
}
