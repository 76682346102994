@import '../../styles/shared.scss';

.concierge-talent-results-outer {
  .options {
    @extend %flex-row;
    align-items: center;
    margin-bottom: 10px;
    gap: 50px;

    .search {
      .input {
        padding: 8px 12px;
        font-size: 0.7rem;
        color: $dark;
        border: $border-lighter;
        border-radius: 5px;
        width: 250px;
        outline: none;
      }
    }

    .toggles {
      @extend %flex-row;
      gap: 10px;

      .analytics-toggle {
        @extend %flex-row;
        gap: 5px;
        font-size: 0.7rem;
      }
    }

    .sorting-options-container {
      @extend %flex-row;
      align-items: center;
      gap: 5px;
      font-size: 0.7rem;

      .sorting-option {
        @extend %flex-row;
        align-items: center;
        gap: 5px;
        padding: 5px 10px;
        border-radius: 100px;
        border: $border-lighter;
        cursor: pointer;

        &.selected {
          background: $dark;
          color: $white;
        }
      }
    }
  }

  .unset-users {
    @extend %flex-row;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
  }

  .talent-holder {
    @extend %flex-row;
    justify-content: center;
    gap: 10px;
    flex-wrap: wrap;
    margin: 5px 0;
  }

  .unsupported-talent {
    text-align: center;
    width: 100%;
    font-size: 2rem;
    font-weight: 600;
    font-family: $font-header;
    margin-bottom: 10px;
    margin-top: 2rem;
  }
}
