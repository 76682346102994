@import '../../styles/shared.scss';

.brand-waitlist-decision-modal-outer {
  background: white;
  max-height: 600px;
  overflow-y: auto;

  .brand-waitlist-decision-modal-inner {
    @extend %flex-col;
    gap: 1.7rem;
    padding: 10px;

    .header-container {
      .name {
        font-size: 2rem;
        font-weight: 600;
        font-family: $font-header;
      }

      .domain-email {
        font-size: 0.9rem;

        svg {
          @extend %btn-basic;
        }
      }
    }

    .pitch-container {
      font-size: 1.1rem;
    }

    .form-container {
      @extend %flex-col;
      gap: 0.5rem;

      .form-group {
        label {
          @extend %label;
          font-size: 0.8rem;
          display: block;
          margin-bottom: 3px;
          box-sizing: border-box;
        }

        select {
          font-size: 0.8rem;
          padding: 0.5rem;
          border-radius: 4px;
          border: $border-light;
          width: 100%;
          cursor: pointer;
          box-sizing: border-box;
        }

        input {
          font-size: 0.8rem;
          padding: 0.5rem;
          border-radius: 4px;
          border: $border-light;
          width: 100%;
          box-sizing: border-box;
        }
      }
    }

    .email-preview-container {
      textarea {
        font-family: $font-body-new;
        font-size: 0.8rem;
        padding: 0.5rem;
        border-radius: 4px;
        border: $border-light;
        width: 100%;
        box-sizing: border-box;
        height: 200px;
      }
    }
  }
}
