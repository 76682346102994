@import '../../styles/shared.scss';

.scraper-modal-outer {
  background: white;
  border-radius: 8px;
  position: relative;
  padding-top: 20px;

  .scraper-modal-inner {
    font-family: $font-body-new;
    margin-bottom: 10px;
    position: relative;
    padding-bottom: 80px;

    overflow-x: hidden;
    overflow-y: scroll;
    max-height: 400px;

    .scraper-modal-header {
      @extend %flex-col;
      gap: 10px;

      .scraper-modal-title {
        @extend %flex-row;
        gap: 20px;
        align-items: center;
        font-family: $font-header;
        font-size: 36px;
        font-weight: 600;

        .scraper-modal-title-label {
          font-size: 12px;
          font-family: $font-body-new;
          color: $medium;
          padding: 5px 15px;
          border-radius: 4px;
          width: max-content;
        }
      }

      .scraper-modal-subtitle {
        font-size: 14px;
        color: $medium;
      }
    }

    .scraper-modal-details {
      @extend %flex-row;
      gap: 20px;
      flex-wrap: wrap;
      justify-content: space-between;
      text-align: center;
      margin: 30px 0;

      .scraper-modal-detail {
        @extend %flex-col;
        gap: 5px;

        .scraper-modal-detail-label {
          font-size: 12px;
          color: $light;
        }

        .scraper-modal-detail-value {
          @extend %flex-row;
          gap: 5px;
          font-size: 20px;
          color: $dark;
          font-weight: 600;
          justify-content: center;
          align-items: center;

          svg {
            height: 12px;
            width: 12px;
          }
        }
      }
    }

    .scraper-modal-notes {
      margin-top: 40px;

      .scraper-modal-notes-header {
        font-size: 16px;
        font-weight: 600;
        color: $dark;
        margin-bottom: 10px;
      }

      .scraper-modal-notes-text {
        font-size: 14px;
        color: $medium;
        font-family: $font-body-new;
        width: 100%;
        background: $offwhite;
        padding: 10px;
        border-radius: 8px;
        border: none;
        outline: none;
      }
    }
  }

  .scraper-modal-footer {
    @extend %flex-row;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    justify-content: space-between;
    border-top: $border-offwhite;
    padding: 20px 0 10px 0;
    background: white;

    .scraper-modal-footer-button-container {
      @extend %flex-row;
      gap: 10px;

      .scraper-modal-footer-button {
        @extend %btn-basic;
        @extend %label;
        font-size: 12px;
        padding: 8px 16px;
        border-radius: 4px;
        outline: none;
        border: none;

        &.save {
          color: $white;
          background: $darkest;
        }

        &.force-run {
          color: $white;
          background: $dark;
        }

        &.needs-fix {
          color: $white;
          background: #f0ad4e;
        }

        &.delete {
          color: $white;
          background: $error;
        }

        &.notify-engineer {
          color: $white;
          background: $darkest;
        }

        &.create-manual {
          color: $white;
          background: $darkest;
        }
      }
    }

  }
}
