@import '../../../styles/shared.scss';

.product-search-result-outer-container {
  @extend %flex-col;
  box-sizing: border-box;
  padding: 8px 4px 0;
  width: calc(33%);

  .product-search-result-inner-container {
    @extend %flex-col-center;
    height: 100%;
    justify-content: space-between;
    border: $border-offwhite;
    padding: 8px 12px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    background: white;
    border-radius: 8px;
    position: relative;

    &.selected {
      border: $border-dark;
    }

    &:hover {
      transform: scale(0.98);
    }

    &.match-mode {
      cursor: default;
      transform: none;
    }

    .product-wrapper {
      @extend %flex-col-center;
      width: 100%;

      .product-data {
        width: 100%;

        .brand {
          font-size: 13px;
          color: $darkest;
          font-weight: bold;
          text-transform: uppercase;
        }

        .title {
          font-size: 14px;
          text-transform: uppercase;
          color: $darker;
          line-height: 16px;
        }

        .category {
          font-size: 10px;
          text-transform: uppercase;
          color: $light;
        }
      }
    }

    img {
      width: 200px;
      height: 200px;
      max-width: 100%;
      margin-bottom: 12px;
      object-fit: contain;
    }

    .metadata {
      font-size: 12px;
      margin: 24px 0 4px;
      color: $medium;
      text-transform: uppercase;
    }

    .match-btn {
      @extend %btn-dark;
      width: 100%;
    }
    
    .ranking-box {
      @extend %flex-row-middle;
      align-self: stretch;
      background: $offwhite;
      border-radius: 2px;
      padding: 4px 0;
      color: $dark;

      &.empty {
        display: none;
      }

      .stat {
        @extend %flex-row-middle;
        flex: 2;
        padding: 0 8px;
        font-family: $font-header;

        .number {
          font-size: 30px;
          font-weight: bold;
          margin-right: 4px;
          margin-top: -4px;
        }
        .desc {
          font-size: 13px;
          line-height: 12px;
        }

        &:not(.solo):last-child {
          flex: 1;
          color: $medium;

          .number {
            font-size: 22px;
            font-weight: bold;
            margin-right: 4px;
            margin-top: -4px;
          }
          .desc {
            font-size: 9px;
            line-height: 10px;
          }
        }

        &.solo {
          justify-content: center;

          .number {
            font-size: 28px;
            font-weight: bold;
            margin-right: 8px;
            margin-top: -4px;
          }
          .desc {
            font-size: 16px;
            line-height: 18px;
          }
        }
      }
    }

    .icons {
      @extend %flex-row;
      position: absolute;
      top: 4px;
      right: 4px;

      .warning-icn,
      .hidden-icn {
        font-size: 12px;
       
        svg {
          color: $lighter;
          margin-left: 2px;
        }

        &.full {
          svg {
            color: $darkest;
          }
        }
      }

      .hidden-icn {
        font-size: 13px;
      }
    }
  }
}
