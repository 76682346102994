@import '../../styles/shared.scss';

.newsletter-outer-container {
  .newsletter-sections {
    .newsletter-header-section {
      padding: 16px 20px 12px;
      background: $prettymuchwhite;
      border-bottom: $border-lightest;

      .newsletter-header {
        font-size: 24px;
        font-family: $font-header;
        font-weight: bold;
      }
      .newsletter-metadata {
        @extend %flex-row;
        font-size: 12px;
        line-height: 10px;
        font-weight: bold;

        .data {
          margin-top: 4px;

          &:not(:last-child) {
            margin-right: 6px;
            padding-right: 6px;
            border-right: $border-offwhite;
          }
        }
      }
    }
  }

  .add-new-section {
    margin-top: 48px;

    .add-new-title {
      margin-bottom: 12px;
      color: $darkest;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: bold;
    }

    .section-options {
      @extend %flex-row-middle;

      .section-option {
        @extend %btn-darker;
        margin-right: 12px;
        margin-bottom: 12px;
        font-size: 10px;
        svg {
          margin-left: 8px;
          font-size: 12px;
        }
      }
    }
  }

  .actions {
    @extend %flex-row;
    position: absolute;
    top: 8px;
    right: 8px;
    color: $darkest;

    .action {
      @extend %btn-basic;
      margin-left: 8px;

      &.inactive {
        color: $lighter;
      }
    }
  }
}
