@import '../../styles/shared.scss';

.tagpill-container {
  @extend %flex-row-middle;
  gap: 4px;
  background-color: white;
  border-radius: 500px;
  border: $border-lightest;
  color: $darkest;
  height: 32px;
  position: relative;

  &:hover {
    .remove-button {
      opacity: 1;
    }
  }

  .tag-text {
    @extend %flex-center;
    padding: 8px 12px;
    @extend %label;
    text-transform: none;
    font-size: 10px;
  }

  .remove-button {
    @extend %btn-basic;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    display: grid;
    place-content: center;
    font-size: 3px;
    position: absolute;
    top: 0;
    right: 0;
    opacity: 0;
    background-color: $lightest;
    transform: translate(30%, -30%);
    transition: opacity 0.2s;

    svg {
      height: 10px;
      width: 10px;
    }

    &:hover {
      opacity: 1;
    }
  }
}
