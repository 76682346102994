@import '../../styles/shared.scss';

.modal_outer_container {
  // @extend %flex_center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: grid;
  place-items: center;
  z-index: 10;

  .modal_inner_container {
    // @extend %flex_col;
    width: min(90vw, 640px);
    // min-height: 320px;
    background: white;
    border-radius: 10px;
    overflow: hidden;

    .modal_padded_content {
      margin: 1rem 2rem;
      height: 100%;

      .title {
        font-size: 1.25rem;
        font-weight: bold;
        color: black;
        margin-bottom: 1.5rem;
      }
    }
  }
}

.modal_generic_section + .modal_generic_section {
  margin-top: 1rem;
}

.modal_generic_button {
  background-color: #4a4a4a;
  color: white;
  margin: 1rem 0;
  text-align: center;
  padding: 8px 16px;
  border-radius: 5px;
  font-weight: bold;
  text-transform: uppercase;
  cursor: pointer;
  transition: box-shadow 0.3s ease;
}

.modal_generic_button:hover {
  box-shadow: 0px 0px 0px 3px #21212155;
  text-decoration: none;
}

.modal_generic_input {
  margin: 1rem 0;

  .text {
    margin: 0.5rem 0;

    .subtitle {
      font-size: 0.8rem;
      font-weight: normal;
    }

    .input_title {
      font-size: 0.95rem;
      font-weight: bold;
      color: #616161;
    }
  }

  .input {
    border-radius: 5px;
    outline: none;
    border: 1px solid #d2d2d2;
    font-family: 'Karla';
    font-size: 1.05rem;
    transition: border-color 0.5s ease;
  }

  .input[type='text'],
  .input[type='number'] {
    width: calc(100% - 22px);
    padding: 5px 10px;
  }

  .input[type='checkbox'] {
    cursor: pointer;
  }

  .input:focus {
    border-color: #212121;
  }
}
