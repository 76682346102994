@import '../../styles/shared.scss';

.suggested-codes {
  @extend %flex-row;
  gap: 10px;
  margin: 20px 0;
  flex-wrap: wrap;

  .suggested-code {
    @extend %label;
    @extend %flex-row;
    @extend %btn-basic;
    gap: 4px;
    padding: 4px 8px;
    border: $border-lightest;
    border-radius: 4px;
    font-size: 10px;
  }
}

.waitlist-outer-container {
  @extend %flex-col;
  font-family: $font-body;
  box-sizing: border-box;

  .waitlist-inner-container {
    @extend %flex-col;
    margin: 44px auto;
    max-width: 1600px;
    width: 90%;


    .waitlist-title-container {
      h1 {
        margin: 0;
      }

      .waitlist-priority-tags {
        margin: 20px 0;

        .waitlist-priority-tags-title {
          font-family: $font-header;
          font-weight: bold;
          letter-spacing: 0.7px;
          font-size: 14px;
          margin-bottom: 5px;
        }

        .waitlist-priority-tags-container {
          @extend %flex-row;
          gap: 10px;
          flex-wrap: wrap;

          .waitlist-priority-tag {
            @extend %label;
            @extend %btn-basic;
            padding: 4px 8px;
            border: $border-lightest;
            border-radius: 4px;
            font-size: 10px;

            &.isActive {
              background: $dark;
              color: white;
            }

            &.add {
              background: $primary;
              color: white;
            }
          }
        }
      }
    }

    .results-container {
      opacity: 1;
      transition: opacity 0.2s ease-out;

      &.isSearching {
        opacity: 0.3;
      }

      .row {
        @extend %flex-row-middle;
        border-bottom: $border-offwhite;
        border-left: $border-offwhite;
        border-right: $border-offwhite;
        padding: 2px 0;
        font-size: 12px;

        &:nth-child(odd) {
          background: $offwhite;
        }

        &.header {
          background: $darker;
          border-top-right-radius: 8px;
          border-top-left-radius: 8px;
          color: white;
          padding: 5px 0;
          font-weight: 800;
        }

        &.rejected {
          text-decoration: line-through;
          color: $lighter;
        }

        .cell {
          flex: 1;
          padding: 2px 8px;

          max-height: 40px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;

          &:hover {
            max-height: none;
            overflow: visible;
            text-overflow: unset;
            -webkit-line-clamp: unset;
          }

          &.md {
            flex: 2;
          }

          &.lg {
            flex: 3;
          }

          &.xl {
            flex: 6;
            max-width: 50%;
          }

          &.center {
            text-align: center;
          }

          .social-icon {
            width: 16px;
            height: 16px;
            margin-right: 4px;

            &:hover {
              opacity: 0.6;
            }
          }

          .decision {
            text-align: center;
            text-transform: uppercase;
            font-size: 10px;
            white-space: nowrap;

            &.remind {
              /* @extend %flex-center; */
              @extend %flex-col;
              gap: 4px;
              position: relative;

              .action {
                padding: 2px 6px;
                background: $dark;
                color: white;
                border-radius: 2px;
                font-weight: bold;

                &.manual {
                  background: $lightest;
                  color: $dark;
                  /* position: absolute; */
                  /* right: 0; */
                }
              }
            }

            &.user {
              svg {
                color: $success;
              }
            }

            .action {
              @extend %btn-basic;
            }
          }

          &.btn {
            cursor: pointer;
          }

          .btns {
            @extend %flex-row-middle;
            justify-content: center;

            .btn {
              padding: 4px 8px;
              text-transform: uppercase;
              font-size: 12px;
              font-weight: bold;
              cursor: pointer;

              &:not(:last-child) {
                border-right: $border-offwhite;
              }

              &:hover {
                opacity: 0.8;
              }
            }
          }
        }
      }
    }
  }
}
