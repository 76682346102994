@import '../../../styles/shared.scss';

.concierge-modal-main-container-outer {
  position: relative;
  width: 100%;

  .main-container-shortcut-bar {
    position: absolute;
    background-color: white;
    top: 0;
    left: 0;
    width: 100%;
    @extend %flex-row;
    gap: 20px;
    padding: 16px 64px 10px 24px;
    border-bottom: $border-lightest;
    box-sizing: border-box;
    overflow-x: scroll;
    z-index: 2;

    .shortcut {
      font-size: 0.8rem;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 0.5px;
      padding: 8px 12px;
      border: $border-lightest;
      border-radius: 5px;
      cursor: pointer;
      transition: all 0.3s ease-in-out;
      white-space: nowrap;

      &.hover {
        border: $border-dark;
      }

      &.selected {
        background-color: $primary;
        color: white;
      }
    }
  }

  .main-container-sections {
    width: 100%;
    box-sizing: border-box;
    padding: 24px;
    height: 100%;
    overflow: scroll;

    .talent-report-card {
      margin-bottom: 2rem;

      &:first-child {
        margin-top: 64px;
      }

      .header {
        @extend %flex-row;
        justify-content: space-between;

        .description {
          max-width: 350px;

          .title {
            font-size: 1.6rem;
            font-weight: 600;
            font-family: $font-header;
            color: $darkest;
          }

          .sub-title {
            font-size: 0.7rem;
            color: $dark;
          }
        }

        .talent-report-controls {
          display: flex;
          gap: 10px;
          text-align: center;
          text-transform: lowercase;

          .rounded-button {
            @extend %btn-basic;
            @extend %flex-row;
            gap: 5px;
            padding: 12px 16px;
            font-size: 12px;
            font-weight: 700;
            color: $black;
            border: $border-darkest;
            box-sizing: border-box;
            white-space: nowrap;
            border-radius: 6px;
            text-transform: uppercase;
            height: max-content;
          }

          .radio-btns {
            @extend %flex-row-middle;
            border: $border-lightest;
            border-radius: 6px;
            overflow: hidden;
            height: max-content;

            .radio-btn {
              cursor: pointer;
              padding: 12px 8px;
              font-size: 12px;
              font-weight: 700;
              letter-spacing: 0.1em;
              background: $white;
              color: $dark;
              white-space: nowrap;
              position: relative;
              text-transform: uppercase;

              &:first-child {
                border-radius: 6px 0px 0px 6px;
              }
              &:last-child {
                border-radius: 0px 6px 6px 0px;
              }

              &.active {
                background: $medium;
                color: white;
              }
            }
          }
        }
      }
    }
  }
}
