@import '../../../styles/shared.scss';

.ml-api-tabs-outer-container {
  margin-bottom: 24px;
  .ml-api-tabs {
    @extend %flex-row;
    gap: 12px;

    .ml-api-tab {
      @extend %btn-basic;
      border: $border-lightest;
      padding: 4px 16px;
      border-radius: 24px;
      text-transform: uppercase;
      font-size: 12px;

      &.selected {
        border: $border-darkest;
        background: $darkest;
        color: white;
        font-weight: bold;
      }

      &.disabled {
        opacity: 0.4;
      }
    }
  }
}
