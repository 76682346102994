@import '../../styles/shared.scss';

.growth_scraper_outer_container {
  @extend %flex-col;
  font-family: $font-body;
  box-sizing: border-box;

  width: 100%;
  display: grid;
  place-items: center;

  .growth_inner_container {
    width: 90%;
    margin: 2rem 0;

    .actions {
      @extend %flex-row-middle;
      margin: 1rem 0;
      gap: 12px;

      input {
        @extend %input-shared;
        min-width: 320px;
      }

      .button {
        display: inline-block;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        padding: 4px 8px;
        border: 1px solid rgb(0 0 0 / 0.4);
        border-radius: 5px;
        cursor: pointer;
        transition: box-shadow 0.3s ease;

        svg {
          margin-right: 4px;
        }

        &.disabled {
          opacity: 0.5;
          cursor: not-allowed;
        }
      }

      .button:not(.disabled):hover {
        box-shadow: 0px 0px 0px 3px #238d5d33;
        text-decoration: none;
        color: black;
      }

      .button + .button {
        margin-left: 1rem;
      }
    }

    .row {
      @extend %flex-row-middle;
      border-bottom: $border-offwhite;
      border-left: $border-offwhite;
      border-right: $border-offwhite;
      padding: 2px 0;
      font-size: 13px;
      cursor: pointer;

      &:nth-child(even) {
        background: $prettymuchwhite;
      }

      &.warning {
        background: $yield;
      }

      &.header {
        background: $darker;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;

        .cell {
          color: white;
          overflow: visible;

          svg {
            margin: 2px 0 0 4px;
            opacity: 0.3;

            &.active {
              opacity: 1;
            }
          }
        }
      }

      .delete {
        background: $darker;
        color: white;
        padding: 2px 4px;
        border-radius: 4px;
        cursor: pointer;
      }

      .cell {
        @extend %flex-row;
        flex: 1;
        padding: 2px 8px;
        white-space: nowrap;
        color: $darker;
        overflow: hidden;

        &.clickable {
          @extend %btn-basic;
        }

        &.xs {
          max-width: 48px;
        }
        &.sm {
          max-width: 90px;
        }
        &.md {
          max-width: 140px;
        }
        &.lg {
          max-width: 240px;
        }
      }
    }
  }
}
