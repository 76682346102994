@import '../../styles/shared.scss';

.hierarchy-outer-container {
  @extend %flex-row;
  font-family: $font-body;
  box-sizing: border-box;

  .panel {
    @extend %flex-col;
    padding: 12px 16px;
    border: $border-offwhite;
    margin: 24px 8px;
    flex: 1;
    position: relative;
    min-width: 180px;
    max-height: 80vh;
    overflow-y: auto;

    .row {
      @extend %flex-row-middle-spread;
      background: $offwhite;
      padding: 4px 12px;
      margin: 2px 0;
      cursor: pointer;

      &.level-two {
        padding-left: 24px;
      }
      &.level-three {
        padding-left: 48px;
      }

      &.active {
        font-weight: bold;
      }

      .main {
      }
      .secondary {
        @extend %flex-row-middle;
        font-size: 12px;

        svg {
          margin-left: 6px;
        }
      }
    }

    form {
      @extend %flex-col;
      align-self: stretch;

      &.level-two {
        input {
          padding-left: 24px;
        }
      }
      &.level-three {
        input {
          padding-left: 48px;
        }
      }
    }
  }

  input,
  textarea {
    @extend %input-shared;
  }
}
