@import '../../styles/shared.scss';

.chat-message-container {
  @extend %flex-row;
  margin-bottom: 12px;
  padding: 4px 16px;

  &.brand {
    .image-container {
      img {
        object-fit: contain;
      }
    }
  }

  &.brand {
    justify-content: flex-end;
    .sender-and-receiver {
      margin-left: 12px;
      order: 2;

      .sender {
        order: 3;
      }

      .arrow {
        order: 2;
        transform: rotateY(180deg);
      }

      .receiver {
        order: 1;
      }
    }
    .message-block {
      border-top-right-radius: 0;
    }
  }
  &.user {
    justify-content: flex-start;

    .sender-and-receiver {
      margin-right: 12px;
    }
    .message-block {
      border-top-left-radius: 0;
    }
  }

  .sender-and-receiver {
    @extend %flex-row-middle;

    .image-container {
      &.brand {
        img {
          object-fit: contain;
          padding: 2px;
        }
      }
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        object-fit: cover;
      }
    }
    svg {
      margin: 0 6px;
      font-size: 10px;
    }
  }
  .message-block {
    @extend %flex-col;
    background: $whynotusewhite;
    border: $border-offwhite;
    border-radius: 10px;
    padding: 12px 16px 24px;
    color: $dark;
    font-size: 12px;
    margin-top: 4px;
    white-space: pre-line;
    position: relative;

    .time {
      position: absolute;
      bottom: 4px;
      right: 4px;
      font-size: 9px;
      color: $medium;
      white-space: nowrap;
    }
  }
}

.automated-chat-message-container {
  @extend %flex-center-vertical;
  width: 90%;
  max-width: 600px;
  margin: 24px auto;
  border-top: $border-offwhite;

  .automated-chat-message {
    background: white;
    transform: translateY(-8px);
    padding: 0 16px;
    font-size: 12px;
    letter-spacing: 0.2px;
    color: $dark;
  }
}
