/*
 *  WebFont Package Licensed to ShopMy
 */

/* Para Supreme Light Normal */
@font-face {
  font-family: Para Supreme Normal;
  src: url('eot/ParaSupreme-LightNormal.eot'); /* IE9 Compat Modes */
  src: url('eot/ParaSupreme-LightNormal.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('woff2/Para Supreme Light Normal.woff2') format('woff2'),
    /* Super Modern Browsers */ url('woff/ParaSupreme-LightNormal.woff') format('woff'); /* Pretty Modern Browsers */
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

/* Para Supreme Regular Normal */
@font-face {
  font-family: Para Supreme Normal;
  src: url('eot/ParaSupreme-RegularNormal.eot'); /* IE9 Compat Modes */
  src: url('eot/ParaSupreme-RegularNormal.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('woff2/Para Supreme Regular Normal.woff2') format('woff2'),
    /* Super Modern Browsers */ url('woff/ParaSupreme-RegularNormal.woff') format('woff'); /* Pretty Modern Browsers */
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

/* Para Supreme Medium Normal */
@font-face {
  font-family: Para Supreme Normal;
  src: url('eot/ParaSupreme-MediumNormal.eot'); /* IE9 Compat Modes */
  src: url('eot/ParaSupreme-MediumNormal.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('woff2/Para Supreme Medium Normal.woff2') format('woff2'),
    /* Super Modern Browsers */ url('woff/ParaSupreme-MediumNormal.woff') format('woff'); /* Pretty Modern Browsers */
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

/* Para Supreme Bold Normal */
@font-face {
  font-family: Para Supreme Normal;
  src: url('eot/ParaSupreme-BoldNormal.eot'); /* IE9 Compat Modes */
  src: url('eot/ParaSupreme-BoldNormal.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('woff2/Para Supreme Bold Normal.woff2') format('woff2'),
    /* Super Modern Browsers */ url('woff/ParaSupreme-BoldNormal.woff') format('woff'); /* Pretty Modern Browsers */
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
