@import '../../styles/shared.scss';

.newsletters-outer-container {
  font-family: $font-body;

  .newsletters-container {
    max-width: 1024px;
    width: 90vw;
    margin: 0 auto;
  }

  .header-section {
    @extend %flex-row-middle-spread;
    margin-bottom: 12px;

    .create-btn {
      @extend %btn-darkest;
      text-transform: uppercase;
    }
  }

  .newsletter-rows {
    .newsletter-row {
      border: $border-offwhite;
      border-radius: 10px;
      margin-bottom: 12px;
      overflow: hidden;
      position: relative;
      font-size: 14px;

      &:not(.selected) {
        padding: 18px;
        &:hover {
          @extend %btn-basic;
          border: $border-medium;
        }
        color: $medium;
      }

      &.selected {
        border: $border-lightest;
      }
    }
  }
}
