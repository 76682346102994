@import '../../styles/shared.scss';

.contract-task-templates-outer-container {
  @extend %flex-col;
  font-family: $font-body;
  box-sizing: border-box;

  .contract-task-templates-inner-container {
    @extend %flex-col;
    margin: 32px auto 44px;
    max-width: 1800px;
    width: 90%;

    .row {
      @extend %flex-row-middle;
      border-bottom: $border-offwhite;
      border-left: $border-offwhite;
      border-right: $border-offwhite;
      padding: 2px 0;
      font-size: 13px;

      &.header {
        background: $darker;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;

        .cell {
          color: white;
          overflow: visible;
        }
      }

      &.odd {
        background: $prettymuchwhite;
      }

      &.disabled {
        opacity: 0.15;
      }

      .cell {
        @extend %flex-row;
        flex: 1;
        padding: 2px 8px;
        white-space: nowrap;
        color: $darker;
        overflow: hidden;

        &.clickable {
          @extend %btn-basic;
        }

        &.xs {
          max-width: 48px;
        }
        &.sm {
          max-width: 90px;
        }
        &.md {
          max-width: 140px;
        }
        &.lg {
          max-width: 240px;
        }
      }
    }
    .add-new-template {
      @extend %flex-center;
      margin-top: 12px;

      .btn {
        @extend %btn-text;
      }
    }
  }
}
